import { RootState } from "domain/store";

export const selectSeances = (state: RootState) => {
  return state.seances.seances;
};

export const selectIsSeancesLoading = (state: RootState) => {
  return state.seances.isSeancesLoading;
};

export const selectSeance = (state: RootState) => {
  return state.seances.seance;
};

export const selectIsSeanceLoading = (state: RootState) => {
  return state.seances.isSeanceLoading;
};

export const selectSeanceAdvice = (state: RootState) => {
  return state.seances.advice;
};

export const selectSeanceTechniques = (state: RootState) => {
  return state.seances.seanceTechniques;
};
