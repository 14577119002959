import { updateSeance } from "domain/seances/seances.actions";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { HiLightBulb, HiPencil } from "react-icons/hi";
import { useAppDispatch } from "ui/hook/store";
import { translate } from "ui/i18n";
import { INTextarea } from "ui/pages/app/components/INTextarea";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { Inputs } from "ui/pages/inputsType";
import { Snackbar } from "ui/pages/snackbar";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  seanceId: number;
  fetchSeance: () => Promise<void>;
  note: string;
  isLoading: boolean;
}

export function UpdateNote({ seanceId, fetchSeance, note, isLoading }: IProps) {
  const dispatch = useAppDispatch();
  const [editNote, setEditNote] = useState(false);
  const isDesktop = useWindowWidth();

  const noteFormManager = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      note,
    },
  });

  const saveNote = async (data: Inputs) => {
    const result = await dispatch(
      updateSeance({
        seance: {
          note: data.note,
        },
        id: seanceId,
      })
    ).unwrap();

    if (result.success && result.id) {
      fetchSeance();
      Snackbar.showSuccess({
        title: translate("ns1:CreateSeance.UpdatedNote"),
      });
      setEditNote(false);
    }
  };

  return (
    <div className="my-8 w-full rounded-2xl bg-white ">
      <div className="flex flex-col space-y-4 p-6">
        <div className="flex w-full justify-between">
          <p className="text-sm font-bold text-secondaryGrey-900 md:text-base">
            {translate("ns1:CreateSeance.Note")}
          </p>
          {!editNote && (
            <button onClick={() => setEditNote(true)}>
              <HiPencil color="#485585" size={24} />
            </button>
          )}
        </div>

        <div className="flex items-center rounded-[20px] bg-darkGrey-200 px-6 py-4">
          <div className="me-4 h-6 w-6">
            <HiLightBulb size={24} color="#485585" />
          </div>
          <div className="text-sm font-medium text-darkGrey-800 md:text-base">
            <p>
              <Trans
                i18nKey={
                  "CreateSeance.NoteToNotForget" as never[] | (string & never[])
                }
                components={[<span className="font-bold" />]}
              />
            </p>
            <p>{translate("ns1:CreateSeance.NoteDisplayed")}</p>
          </div>
        </div>

        {!editNote ? (
          <p className="text-sm text-secondaryGrey-700 md:text-base">
            {note ? (
              note
            ) : (
              <span className="text-grey-200">
                {translate("ns1:CreateSeance.NoNote")}
              </span>
            )}
          </p>
        ) : (
          <form onSubmit={noteFormManager.handleSubmit(saveNote)}>
            <INTextarea
              type="textarea"
              error={noteFormManager.formState.errors.note}
              placeholder={translate("ns1:Input.Placeholders.WriteHere")}
              className="mb-4 md:mb-6"
              register={{
                ...noteFormManager.register("note", {
                  required: translate("ns1:Input.Errors.Required"),
                }),
              }}
              rows={isDesktop ? 2 : 4}
            />
            <div className="flex flex-col space-x-0 space-y-2 md:flex-row md:space-x-4 md:space-y-0">
              <PrimaryButton
                type="submit"
                height={isDesktop ? "46px" : "36px"}
                width="100%"
                disabled={!noteFormManager.formState.isValid || isLoading}
              >
                {translate("ns1:Button.Validate")}
              </PrimaryButton>

              <PrimaryButton
                height={isDesktop ? "46px" : "36px"}
                width="100%"
                disabled={isLoading}
                outlined
                onClick={() => setEditNote(false)}
              >
                {translate("ns1:Button.Cancel")}
              </PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
