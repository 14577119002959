import { HiChat, HiThumbUp } from "react-icons/hi";
import { translate } from "ui/i18n";

interface IProps {
  positive: boolean;
  by: string;
  text: string;
  date: string;
}

export function Opinion({ positive, by, text, date }: IProps) {
  return (
    <div className="mt-10">
      <div className="flex flex-row space-x-4">
        <span
          className={`flex h-12 w-12 min-w-12 items-center justify-center rounded-2xl ${
            positive ? "bg-paleGreen-100" : "bg-pink-100"
          }`}
        >
          {positive ? (
            <HiThumbUp color="#409E91" size={24} />
          ) : (
            <HiChat color="#E14277" size={24} />
          )}
        </span>
        <div className="flex w-full flex-row items-center">
          <div className="flex w-full flex-col space-y-1">
            <p className="text-base font-bold text-secondaryGrey-900">
              {positive
                ? translate("ns1:Activities.ApprovedBy")
                : translate("ns1:Activities.WhatDoTheyThink")}
            </p>
            <p className="text-xs font-medium text-darkGrey-700">{by}</p>
          </div>
          <p className="text-xs font-medium text-darkGrey-700">{date}</p>
        </div>
      </div>
      <p className="mt-8 text-sm font-medium text-darkGrey-700">{text}</p>
    </div>
  );
}
