import { Carousel } from "react-responsive-carousel";
import BannerLightGreenDesktop from "ui/assets/svg/carrousel-banners-desktop/banner-light-green-desktop.svg";
import BannerPaleGreenDesktop from "ui/assets/svg/carrousel-banners-desktop/banner-pale-green-desktop.svg";
import BannerPinkDesktop from "ui/assets/svg/carrousel-banners-desktop/banner-pink-desktop.svg";
import BannerLightGreenMobile from "ui/assets/svg/carrousel-banners-mobile/banner-light-green-mobile.svg";
import BannerPaleGreenMobile from "ui/assets/svg/carrousel-banners-mobile/banner-pale-green-mobile.svg";
import BannerPinkMobile from "ui/assets/svg/carrousel-banners-mobile/banner-pink-mobile.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { translate } from "ui/i18n";
import { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useWindowWidth } from "ui/utils/hooks";

export function HomeCarousel() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isDesktop = useWindowWidth();

  const previousImage = () => {
    setCurrentImageIndex(
      currentImageIndex === 0
        ? getCarouselPanels(isDesktop).length - 1
        : currentImageIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex(
      currentImageIndex === getCarouselPanels(isDesktop).length - 1
        ? 0
        : currentImageIndex + 1
    );
  };

  return (
    <div className="relative">
      <Carousel
        className="mt-6 rounded-[20px] md:rounded-3xl"
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        autoPlay
        infiniteLoop
        selectedItem={currentImageIndex}
        onChange={setCurrentImageIndex}
        transitionTime={600}
        interval={5000}
      >
        {getCarouselPanels(isDesktop).map((panel, index) => (
          <div key={index + "panel"}>
            <img
              src={panel.img}
              alt={translate("ns1:Home.BannerAlt")}
              className="rounded-[20px] md:rounded-3xl"
            />
            <div className="absolute left-1/2 top-1/2 flex w-5/6 -translate-x-1/2 -translate-y-1/2 flex-col items-center">
              <div className="mb-2 w-fit rounded-[36px] bg-white/65 px-2.5 py-0.5 text-xs font-semibold md:mb-4">
                {panel.type}
              </div>
              <h1 className="mb-3 text-2xl md:mb-4 md:text-3xl">
                {panel.title}
              </h1>
              <button className="mb-2 rounded-xl bg-darkGrey-800 px-4 py-2 text-sm font-bold text-white md:mb-0">
                {panel.buttonText}
              </button>
            </div>
          </div>
        ))}
      </Carousel>

      {isDesktop && (
        <>
          <button
            className="absolute left-5 top-1/2 flex h-9 w-9 -translate-y-1/2 transform items-center justify-center rounded-xl bg-white"
            onClick={previousImage}
          >
            <HiChevronLeft className="text-darkGrey-900" size={18} />
          </button>
          <button
            className="absolute right-5 top-1/2 flex h-9 w-9 -translate-y-1/2 transform items-center justify-center rounded-xl bg-white"
            onClick={nextImage}
          >
            <HiChevronRight className="text-darkGrey-900" size={18} />
          </button>
        </>
      )}

      <div className=" absolute bottom-4 left-1/2 flex -translate-x-1/2 transform  space-x-3 md:bottom-5">
        {getCarouselPanels(isDesktop).map((_, index) => (
          <button
            key={index + "indicator"}
            onClick={() => setCurrentImageIndex(index)}
            className={`h-2.5 w-2.5 rounded-full ${
              index === currentImageIndex ? "bg-white" : "bg-darkGrey-800"
            }`}
          />
        ))}
      </div>
    </div>
  );
}

const getCarouselPanels = (isDesktop: boolean) => [
  {
    img: isDesktop ? BannerLightGreenDesktop : BannerLightGreenMobile,
    type: "Actualité",
    title: "Innizio fête ses 115 techniques !",
    buttonText: "Voir toutes les techniques",
  },
  {
    img: isDesktop ? BannerPinkDesktop : BannerPinkMobile,
    type: "Techniques",
    title: "Nouvelle technique: Nom",
    buttonText: "Voir la technique",
  },
  {
    img: isDesktop ? BannerPaleGreenDesktop : BannerPaleGreenMobile,
    type: "Evènements",
    title: "Webinaire: Découvrez le coaching d'Innizio",
    buttonText: "M'inscrire",
  },
];
