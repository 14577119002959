export function CardBgPaleGreen200({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5693)">
        <rect width="80" height="80" rx="8" fill="#B5DDD6" />
        <path
          d="M41.0721 58.2153C43.6831 59.7228 44.5777 63.0615 43.0702 65.6725C41.5628 68.2836 38.224 69.1782 35.613 67.6707C33.0019 66.1632 32.1073 62.8245 33.6148 60.2134C35.1223 57.6024 38.461 56.7078 41.0721 58.2153Z"
          fill="#96BCB6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99959 -8.79981C2.79045 -8.79981 0.999592 -7.00895 0.999591 -4.79981L0.999589 30.8002L-12.5628 30.8002C-30.7485 30.8002 -40.7669 51.928 -29.2579 66.0086C-16.4323 81.7 8.99958 72.6407 8.99959 52.3683L8.99959 38.8002L15.2296 38.8002C21.5093 38.8002 26.6001 43.8909 26.6001 50.1707L26.6001 74.172C26.6001 74.5047 26.6063 74.8963 26.6372 75.3179C26.7418 76.7472 27.1548 80.9077 28.6647 85.9481C29.2987 88.0643 31.5282 89.2659 33.6444 88.6319C35.7606 87.998 36.9622 85.7685 36.3282 83.6523C35.0375 79.3439 34.6938 75.7982 34.6158 74.7337C34.605 74.586 34.6001 74.4102 34.6001 74.172L34.6001 50.1707C34.6001 39.4726 25.9276 30.8002 15.2296 30.8002L8.99959 30.8002L8.99959 -4.79981C8.99959 -7.00895 7.20873 -8.79981 4.99959 -8.79981ZM0.999587 38.8002L0.999585 52.3683C0.999583 65.1143 -14.9935 70.8193 -23.0638 60.9457C-30.3028 52.0892 -24.0014 38.8002 -12.5628 38.8002L0.999587 38.8002Z"
          fill="#CCF4ED"
        />
      </g>
      <defs>
        <clipPath id="clip0_266_5693">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
