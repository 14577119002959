export type AuthSession = {
  accessToken: string;
};

export function setSessionInLocalStorage(session: AuthSession) {
  localStorage.setItem("session", JSON.stringify(session));
}

export function removeSessionInLocalStorage() {
  localStorage.removeItem("session");
}

export function getSessionFromLocalStorage(): AuthSession | null {
  const item = localStorage.getItem("session");
  if (!item) return null;
  return JSON.parse(item);
}
