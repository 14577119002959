import toast, { Toast } from "react-hot-toast";
import {
  HiCheck,
  HiOutlineExclamationCircle,
  HiOutlineX,
} from "react-icons/hi";

export function AlertSnackbar({
  type,
  title,
  text,
  t,
}: {
  title: string;
  text?: string;
  type: "success" | "error";
  t: Toast;
}) {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } flex w-fit max-w-sm rounded-2xl bg-white shadow-lg`}
    >
      <div className="flex flex-1 items-start p-4">
        <div className="flex h-14 w-14 items-center justify-center rounded-full bg-darkGrey-100">
          {type === "success" ? (
            <HiCheck size={25} className="text-secondaryGrey-900" />
          ) : (
            <HiOutlineExclamationCircle
              size={25}
              className="text-secondaryGrey-900"
            />
          )}
        </div>
        <div
          className={`ml-3 flex-1 ${text ? "" : "flex h-full items-center"}`}
        >
          <p className="text-base font-bold text-secondaryGrey-900">{title}</p>
          {text && (
            <p className="mt-1 text-sm font-medium text-secondaryGrey-700">
              {text}
            </p>
          )}
        </div>
      </div>
      <div className="p-3">
        <button onClick={() => toast.remove(t.id)} className="">
          <HiOutlineX size={20} className="text-secondaryGrey-900" />
        </button>
      </div>
    </div>
  );
}
