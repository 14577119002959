import { HiX } from "react-icons/hi";
import { translate } from "ui/i18n";

export function CloseButton({
  onClick,
  displayText = true,
}: {
  onClick: () => void;
  displayText?: boolean;
}) {
  return (
    <>
      <button onClick={onClick} className="z-50 flex h-5 items-center">
        <HiX size={20} className="text-darkGrey-800" />
        {displayText && (
          <span className="ms-2 text-[14px] font-bold leading-none text-darkGrey-800">
            {translate("ns1:Button.Close")}
          </span>
        )}
      </button>
    </>
  );
}
