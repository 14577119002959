export function CardBgLightGreen500({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5639)">
        <rect width="80" height="80" rx="8" fill="#53BBAD" />
        <g clipPath="url(#clip1_266_5639)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-30.0303 -6.57073C-31.5202 -8.20183 -34.0503 -8.31629 -35.6814 -6.82639C-39.0932 -3.70992 -42.2248 0.979352 -44.6505 5.77544C-47.0904 10.5993 -49.0084 15.9127 -49.7765 20.4864C-50.1574 22.7551 -50.297 25.0794 -49.9294 27.1627C-49.5652 29.2269 -48.5791 31.6395 -46.193 33.0493C-43.8197 34.4515 -41.1102 34.2395 -38.718 33.4643C-36.3 32.6809 -33.6039 31.1451 -30.6149 28.9485C-20.4329 21.4658 -10.9609 20.7871 -4.95169 23.4012C0.799389 25.903 4.36383 31.8112 2.46719 40.3461C0.484016 49.2703 -0.367756 56.1462 -0.0468025 61.3195C0.27367 66.4851 1.83251 70.7669 5.5215 73.2549C9.01499 75.611 13.3822 75.6089 17.404 75.0189C21.5329 74.4131 26.3188 73.0137 31.3043 71.5181C38.3188 69.4138 43.2462 70.9609 46.0088 73.3727C48.8207 75.8276 49.8529 79.5027 48.6979 82.9674C47.9993 85.0632 49.1319 87.3285 51.2277 88.0271C53.3235 88.7257 55.5888 87.5931 56.2874 85.4973C58.496 78.8717 56.4318 71.8525 51.2701 67.3462C46.0593 62.797 38.136 61.1164 29.0055 63.8555C23.9004 65.3869 19.6889 66.598 16.2427 67.1036C12.6893 67.625 10.9034 67.2352 9.99471 66.6224C9.2815 66.1414 8.18902 64.8728 7.93785 60.8242C7.68715 56.7833 8.3434 50.7813 10.2767 42.0815C12.9301 30.1412 7.80807 20.2277 -1.76048 16.0652C-11.0709 12.0151 -23.5517 13.8298 -35.3524 22.5021C-38.0137 24.4579 -39.9197 25.4443 -41.1838 25.8539C-41.5437 25.9705 -41.8073 26.0274 -41.9915 26.0541C-42.0112 25.977 -42.0315 25.8839 -42.0511 25.7726C-42.1986 24.9367 -42.1898 23.6147 -41.8869 21.8112C-41.2873 18.2405 -39.6965 13.7057 -37.5117 9.38613C-35.3129 5.03877 -32.7043 1.28933 -30.2859 -0.919655C-28.6548 -2.40956 -28.5404 -4.93963 -30.0303 -6.57073ZM-41.8726 26.3823C-41.8722 26.3817 -41.8765 26.3736 -41.8865 26.3599C-41.878 26.3761 -41.873 26.383 -41.8726 26.3823ZM-42.3251 26.0726C-42.3384 26.0694 -42.3455 26.0686 -42.3458 26.069C-42.346 26.0694 -42.3394 26.071 -42.3251 26.0726Z"
            fill="#9BECE1"
          />
          <path
            d="M40.9675 29.6965C45.1138 31.0345 49.5597 28.7579 50.8977 24.6116C52.2357 20.4653 49.9591 16.0194 45.8128 14.6814C41.6665 13.3434 37.2206 15.62 35.8826 19.7663C34.5446 23.9126 36.8212 28.3585 40.9675 29.6965Z"
            fill="#68D8C9"
          />
          <path
            d="M28.7974 42.9035C31.3754 41.4151 32.2587 38.1186 30.7703 35.5405C29.2819 32.9625 25.9853 32.0792 23.4073 33.5676C20.8293 35.0561 19.946 38.3526 21.4344 40.9306C22.9228 43.5087 26.2193 44.392 28.7974 42.9035Z"
            fill="#68D8C9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_266_5639">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_266_5639">
          <rect
            width="120"
            height="100"
            fill="white"
            transform="translate(-60 -10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
