import { TechniquesFilters } from "domain/entities/techniques.entities";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";

export const getActivitiesFilters = (
  filters: TechniquesFilters &
    Partial<ActivitiesPageFilters> &
    Partial<{ duration: DurationFilter; search: string }>
) => {
  const participants = getParticipants(filters.participants);

  return {
    "context[$in]": filters.contexts,
    "type[$in]": filters.types,
    ...(filters.modalities?.includes("remote") && { remote: true }),
    ...(filters.modalities?.includes("onsite") && { onsite: true }),
    ...(filters.duration && { duration: filters.duration }),
    "$sort[createdAt]": filters["$sort[createdAt]"] || -1,
    ...(filters.participants?.length > 0 && participants),
    ...(typeof filters.isInniz === "boolean" && { isInniz: filters.isInniz }),
    ...(filters.pauses === false && { "type[$nin]": ["pause"] }),
    ...(filters.search?.length > 0 && {
      "title[$ilike]": `%${filters.search}%`,
    }),
  };
};

const getParticipants = (participants: string) => {
  if (participants) {
    if (participants.includes("lte")) {
      return { "participants[$lte]": participants.replace("lte", "") };
    } else {
      return { "participants[$gte]": participants.replace("gte", "") };
    }
  } else return null;
};
