export function CardBgPink500({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5670)">
        <rect width="80" height="80" rx="8" fill="#C5205D" />
        <g clipPath="url(#clip1_266_5670)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.6778 88.8C52.8869 88.8001 54.6778 87.0092 54.6778 84.8001C54.6778 66.8514 45.8303 52.9815 35.5159 44.7873C33.3799 43.0904 31.1585 41.6184 28.9129 40.3961C29.18 38.4624 29.2391 36.3617 29.0661 34.0955C28.4162 25.5817 22.428 19.787 15.2767 15.7204C8.09967 11.6391 -1.13721 8.76897 -10.0818 6.37895C-13.3988 5.49264 -16.6522 4.67706 -19.7893 3.89065C-25.3454 2.4978 -30.5367 1.19643 -35.0706 -0.244547C-38.5723 -1.3575 -41.402 -2.47332 -43.4699 -3.64027C-45.6182 -4.85251 -46.4089 -5.82323 -46.6457 -6.3757C-47.5159 -8.40623 -49.8674 -9.34684 -51.8979 -8.47662C-53.9284 -7.6064 -54.869 -5.25488 -53.9988 -3.22437C-52.7303 -0.264568 -50.0984 1.80517 -47.4015 3.32698C-44.6245 4.89409 -41.1867 6.20593 -37.4937 7.37964C-32.7453 8.88883 -27.1074 10.3039 -21.3629 11.7457C-18.2813 12.5191 -15.169 13.3003 -12.147 14.1078C-3.24419 16.4866 5.11498 19.1448 11.3221 22.6746C17.555 26.219 20.7392 30.1182 21.0893 34.7044C21.1574 35.5966 21.178 36.4294 21.1575 37.2065C20.586 37.0483 20.0174 36.9085 19.453 36.7876C14.275 35.6785 8.78604 36.05 4.76908 39.2844C2.73226 40.9244 1.1484 42.8287 0.434323 45.0222C-0.331493 47.3747 0.0264868 49.7164 1.28835 51.6327C3.55771 55.0791 8.07434 56.3647 11.9059 56.3122C16.0123 56.256 20.6735 54.7135 24.1651 50.9605C24.9626 50.1033 25.6776 49.1542 26.3005 48.1139C27.7133 48.9567 29.1358 49.936 30.5396 51.0513C39.2252 57.9514 46.6778 69.6295 46.6778 84.8C46.6778 87.0092 48.4686 88.8 50.6778 88.8ZM18.8491 44.8691C18.6725 45.1004 18.4914 45.3141 18.3079 45.5113C16.5343 47.4178 14.0822 48.2816 11.7964 48.3129C9.63309 48.3426 8.47343 47.6722 8.09843 47.3614C8.24297 47.0626 8.66317 46.4199 9.78636 45.5155C11.1694 44.4019 13.8055 43.7594 17.7775 44.6102C18.1294 44.6856 18.4868 44.7719 18.8491 44.8691Z"
            fill="#EC7598"
          />
          <path
            d="M16.8506 69.8177C19.4286 71.3061 20.3119 74.6026 18.8235 77.1806C17.3351 79.7587 14.0386 80.642 11.4605 79.1536C8.88248 77.6651 7.99918 74.3686 9.48761 71.7906C10.976 69.2125 14.2726 68.3292 16.8506 69.8177Z"
            fill="#E14277"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_266_5670">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_266_5670">
          <rect
            width="120"
            height="100"
            fill="white"
            transform="translate(-60 -10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
