import { AxiosError } from "axios";
import { HttpService } from "data/http-service";
import {
  CreatePersonalStep,
  CreateSeance,
  InsertSeanceTechnique,
  Seance,
  UpdateSeanceTechnique,
} from "domain/entities/seances.entities";
import { translate } from "ui/i18n";
import { Snackbar } from "ui/pages/snackbar";
import {
  seanceDtoSchema,
  seancesDtoSchema,
} from "data/seances/seances.dto.schemas";
import { SeancesGateway } from "domain/seances/seances.gateway";
import {
  ActivitiesPageFilters,
  ActivityAdvice,
  DurationFilter,
} from "domain/entities/activities.entities";
import { getActivitiesFilters } from "data/activities/get-activities-filters";
import { adviceDtoSchema } from "data/activities/activities.dto.schemas";
import { SeanceTechnique } from "domain/entities/techniques.entities";
import { seanceTechniquesDtoSchema } from "data/techniques/techniques.dto.schemas";

export class SeancesHttpGateway implements SeancesGateway {
  async getSeances(
    filters: Partial<ActivitiesPageFilters> &
      Partial<{ duration: DurationFilter }>
  ): Promise<{
    seances: Seance[];
    success: boolean;
  }> {
    const url = "seances";
    return HttpService.get(url, { params: getActivitiesFilters(filters) })
      .then((response) => {
        if (response.status === 200) {
          const parsedSeances = seancesDtoSchema.safeParse(response.data.data);
          if (parsedSeances.success) {
            return {
              seances: parsedSeances.data,
              success: true,
            };
          } else return { seances: [], success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Seances.FindError"),
          });
          return {
            seances: [],
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Seances.FindError"),
        });
        return { seances: [], success: false };
      });
  }

  async getSeance(id: number): Promise<{
    seance: Seance;
    success: boolean;
  }> {
    const url = `seances/${id}`;
    return HttpService.get(url)
      .then((response) => {
        if (response.status === 200) {
          const parsedSeance = seanceDtoSchema.safeParse(response.data);
          if (parsedSeance.success) {
            return {
              seance: {
                ...parsedSeance.data,
                techniques: parsedSeance.data.techniques?.flat(),
              },
              success: true,
            };
          } else return { seance: null, success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Seance.FindError"),
          });
          return {
            seance: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Seance.FindError"),
        });
        return { seance: null, success: false };
      });
  }

  async getSeanceAdvice(id: number): Promise<{
    advice: ActivityAdvice | null;
    success: boolean;
  }> {
    const url = `conseils/random`;
    return HttpService.get(url, { params: { seanceId: id } })
      .then((response) => {
        if (response.status === 200) {
          const parsedAdvice = adviceDtoSchema.safeParse(response.data);
          if (parsedAdvice.success) {
            return {
              advice: parsedAdvice.data,
              success: true,
            };
          } else return { advice: null, success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Advice.FindError"),
          });
          return {
            advice: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Advice.FindError"),
        });
        return { advice: null, success: false };
      });
  }

  async createSeance(seance: CreateSeance): Promise<{
    success: boolean;
    id: number | null;
  }> {
    const url = `seances`;
    return HttpService.post(url, seance)
      .then((response) => {
        if (response.status === 201 && response.data.id) {
          return {
            id: response.data.id,
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.CreationError"),
          });
          return {
            id: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.CreationError"),
        });
        return { id: null, success: false };
      });
  }

  async updateSeance({
    seance,
    id,
  }: {
    seance: CreateSeance;
    id: number;
  }): Promise<{
    success: boolean;
    id: number | null;
  }> {
    const url = `seances/${id}`;
    return HttpService.patch(url, seance)
      .then((response) => {
        if (response.status === 200 && response.data.id) {
          return {
            id: response.data.id,
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.UpdateError"),
          });
          return {
            id: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.UpdateError"),
        });
        return { id: null, success: false };
      });
  }

  async createPersonalStep(technique: CreatePersonalStep): Promise<{
    success: boolean;
    techniqueId: number | null;
  }> {
    const url = `user-techniques`;
    return HttpService.post(url, technique)
      .then((response) => {
        if (response.status === 201 && response.data.id) {
          return {
            techniqueId: response.data.id,
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.PersonalStepCreationError"),
          });
          return {
            techniqueId: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.PersonalStepCreationError"),
        });
        return { techniqueId: null, success: false };
      });
  }

  async bulkInsertTechniques({
    techniques,
    seanceId,
  }: {
    techniques: InsertSeanceTechnique[];
    seanceId: number;
  }): Promise<{
    success: boolean;
  }> {
    const url = `seances/${seanceId}/techniques`;
    return HttpService.post(url, techniques, {
      additionalHeaders: { "X-Service-Method": "batchInsert" },
    })
      .then((response) => {
        if (response.status === 200) {
          return {
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.BulkInsertTechniquesError"),
          });
          return {
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.BulkInsertTechniquesError"),
        });
        return { success: false };
      });
  }

  async insertTechnique({
    technique,
    seanceId,
  }: {
    technique: InsertSeanceTechnique;
    seanceId: number;
  }): Promise<{
    success: boolean;
  }> {
    const url = `seances/${seanceId}/techniques`;
    return HttpService.post(url, technique)
      .then((response) => {
        if (response.status === 201) {
          return {
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.BulkInsertTechniqueError"),
          });
          return {
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.BulkInsertTechniqueError"),
        });
        return { success: false };
      });
  }

  async bulkUpdateTechniques({
    techniques,
    seanceId,
  }: {
    techniques: UpdateSeanceTechnique[];
    seanceId: number;
  }): Promise<{
    success: boolean;
  }> {
    const url = `seances/${seanceId}/techniques`;
    return HttpService.post(url, techniques, {
      additionalHeaders: { "X-Service-Method": "batchUpdate" },
    })
      .then((response) => {
        if (response.status === 200) {
          return {
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.BulkUpdateTechniquesError"),
          });
          return {
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.BulkUpdateTechniquesError"),
        });
        return { success: false };
      });
  }

  async setSeanceActive(seanceId: number): Promise<{
    success: boolean;
  }> {
    const url = `seances/${seanceId}`;
    return HttpService.patch(url, { active: true })
      .then((response) => {
        if (response.status === 200) {
          return {
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.UpdateError"),
          });
          return {
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.UpdateError"),
        });
        return { success: false };
      });
  }

  async deleteSeanceTechnique({
    seanceId,
    techniqueId,
  }: {
    seanceId: number;
    techniqueId: number;
  }): Promise<{
    success: boolean;
  }> {
    const url = `seances/${seanceId}/techniques/${techniqueId}`;
    return HttpService.delete(url)
      .then((response) => {
        if (response.status === 200) {
          return {
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.DeleteTechniquesError"),
          });
          return {
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.DeleteTechniquesError"),
        });
        return { success: false };
      });
  }

  async getSeanceTechniques(seanceId: number): Promise<{
    techniques: SeanceTechnique[];
    success: boolean;
  }> {
    const url = `seances/${seanceId}/techniques`;
    return HttpService.get(url)
      .then((response) => {
        if (response.status === 200) {
          const parsedTechniques = seanceTechniquesDtoSchema.safeParse(
            response.data.data
          );
          if (parsedTechniques.success) {
            return {
              techniques: parsedTechniques.data,
              success: true,
            };
          } else return { techniques: [], success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:CreateSeance.GetTechniquesError"),
          });
          return {
            techniques: [],
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:CreateSeance.GetTechniquesError"),
        });
        return { techniques: [], success: false };
      });
  }
}
