import {
  SeanceTechnique,
  TechniqueContext,
} from "domain/entities/techniques.entities";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { BackButton } from "ui/pages/app/components/BackButton";
import { useWindowWidth } from "ui/utils/hooks";
import { Modal, ModalContent } from "@chakra-ui/modal";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { useForm } from "react-hook-form";
import { Inputs } from "ui/pages/inputsType";
import { INInput } from "ui/pages/app/components/INInput";
import { INTextarea } from "ui/pages/app/components/INTextarea";
import { INDurationField } from "ui/pages/app/components/INDurationField";
import { Trans } from "react-i18next";
import { HiLightBulb } from "react-icons/hi";
import { parseDuration } from "ui/utils/formatters";
import { selectIsSeanceLoading } from "domain/seances/seances.selectors";
import { createPersonalStep } from "domain/seances/seances.actions";
import { getTechnique } from "domain/techniques/techniques.actions";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  context: TechniqueContext;
  addTechnique: (
    technique: SeanceTechnique,
    context: TechniqueContext
  ) => Promise<{
    success: boolean;
  }>;
}

export function ModalCreatePersonalStep({
  isOpen,
  onClose,
  context,
  addTechnique,
}: IProps) {
  const isLoading = useAppSelector(selectIsSeanceLoading);
  const dispatch = useAppDispatch();
  const isDesktop = useWindowWidth();
  const formDefaultValues = {
    title: "",
    storyline: "",
    duration: "",
  };

  const formManager = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: { ...formDefaultValues },
  });

  const { register, formState, handleSubmit, control, reset } = formManager;
  const { errors, isValid, defaultValues } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(
      createPersonalStep({
        title: data.title,
        storyline: data.storyline,
        ...(data.duration && { duration: parseDuration(data.duration) }),
        context,
      })
    ).unwrap();

    if (result.success && result.techniqueId) {
      const resultTechnique = await dispatch(
        getTechnique(result.techniqueId)
      ).unwrap();

      if (resultTechnique.success) {
        const resultInsert = await addTechnique(
          {
            technique: resultTechnique.technique,
          },
          context
        );

        if (resultInsert.success) {
          reset();
          onClose();
        }
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalContent>
        <div className="flex min-h-dvh w-full flex-col items-center bg-darkGrey-100 px-2 py-4 md:px-12 md:py-9">
          <div className="w-full">
            <div className="flex h-10 w-full items-center justify-between px-2 md:px-0">
              <BackButton onClick={onClose} displayText={isDesktop} />
            </div>

            <div className="mt-8 flex w-full justify-center">
              <h4 className="w-fit text-center font-bold text-secondaryGrey-900">
                {translate(`ns1:CreateSeance.AddCustomStep`)}
              </h4>
            </div>
          </div>

          <div className="max-w-[750px]">
            <div className="mt-10 w-full rounded-[20px] bg-white px-4 py-6 md:px-20 md:py-12">
              <div className="flex items-center rounded-[20px] bg-darkGrey-200 px-6 py-4">
                <div className="me-4 h-6 w-6">
                  <HiLightBulb size={24} color="#485585" />
                </div>
                <div className="text-sm font-medium text-darkGrey-800 md:text-base">
                  <p>
                    <Trans
                      i18nKey={
                        "CreateSeance.PersonalStepExplanation" as
                          | never[]
                          | (string & never[])
                      }
                      components={[<span className="font-bold" />]}
                    />
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-8">
                <INInput
                  type="title"
                  error={errors.title}
                  label={translate("ns1:Input.Labels.Title") + "*"}
                  placeholder={translate(
                    "ns1:Input.Placeholders.ExempleTechniqueTitle"
                  )}
                  className="mb-4 md:mb-6"
                  register={{
                    ...register("title", {
                      required: translate("ns1:Input.Errors.Required"),
                    }),
                  }}
                />

                <INTextarea
                  type="textarea"
                  error={errors.storyline}
                  label={translate("ns1:Input.Labels.Description") + "*"}
                  placeholder={translate(
                    "ns1:Input.Placeholders.ExempleTechniqueDescription"
                  )}
                  className="mb-4 md:mb-6"
                  register={{
                    ...register("storyline", {
                      required: translate("ns1:Input.Errors.Required"),
                    }),
                  }}
                />

                <INDurationField
                  inputName="duration"
                  control={control}
                  label={translate("ns1:Input.Labels.Duration")}
                  error={errors.duration}
                  defaultValue={defaultValues.duration}
                />

                {isDesktop && (
                  <>
                    {!isValid && (
                      <p className="text-medium mt-8 text-sm text-darkGrey-700">
                        *
                        {translate(
                          "ns1:Input.Errors.CompleteAllFieldsToValidate"
                        )}
                      </p>
                    )}

                    <PrimaryButton
                      type="submit"
                      height="52px"
                      width="100%"
                      disabled={!isValid || isLoading}
                      className="mt-8"
                    >
                      {translate("ns1:Button.Validate")}
                    </PrimaryButton>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 flex w-full flex-col items-center bg-white px-6 py-4 shadow-lg">
          {!isDesktop && (
            <PrimaryButton
              height="46px"
              width="100%"
              disabled={isLoading}
              onClick={handleSubmit(handleFormSubmit)}
            >
              {translate("ns1:Button.Validate")}
            </PrimaryButton>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
