import { Trans } from "react-i18next";
import { translate } from "ui/i18n";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { resendVerifyUser } from "domain/auth/auth.actions";
import { selectIsAuthenticationLoading } from "domain/auth/auth.selectors";

export function RegistrationStep2({ email }: { email: string }) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAuthenticationLoading);

  const resendEmail = async () => {
    await dispatch(
      resendVerifyUser({ action: "resendVerifySignup", value: { email } })
    );
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">
        {translate("ns1:Register.ConfirmationByEmail")}
      </h2>
      <h4 className="mt-2 text-center md:mt-4">
        <Trans
          i18nKey={
            "Register.ConfirmationByEmailText" as never[] | (string & never[])
          }
          values={{ email }}
        />
      </h4>

      <div className="mt-2" style={{ maxWidth: 500, width: "100%" }}>
        <img
          src={require("ui/assets/gif/email-confirmation.gif")}
          alt={translate("ns1:Register.ConfirmationEmailImageAlt")}
        />
      </div>

      <div className="text-center font-medium text-darkGrey-700">
        {translate("ns1:Register.NothingReceived")}
      </div>
      <button
        onClick={resendEmail}
        className="font-bold text-darkGrey-700 underline"
        disabled={isLoading}
      >
        {translate("ns1:Register.SendEmailAgain")}
      </button>
    </div>
  );
}
