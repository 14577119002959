import { ChangeEventHandler } from "react";
import { Control, Controller } from "react-hook-form";
import { Inputs } from "ui/pages/inputsType";

interface Props {
  label?: string;
  text: string;
  inputName: string;
  control?: Control<Inputs, any>;
  className?: string;
  required?: boolean;
  bold?: boolean;
  value?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export function INCheckbox({
  label,
  text,
  inputName,
  className,
  control,
  required = false,
  bold,
  value = false,
  onChange,
}: Props) {
  return (
    <div className={className}>
      {label && (
        <label className={`w-full`}>
          <span className="text-sm font-bold text-darkGrey-700">{label}</span>
        </label>
      )}
      {control ? (
        <Controller
          control={control}
          name={inputName}
          rules={{ required }}
          render={({ field }) => (
            <div className="mt-2 flex">
              <input
                type="checkbox"
                id={inputName}
                className={`defaultCheckbox relative me-2 mt-1 h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none
              rounded-md border border-darkGrey-300 bg-white text-white/0 outline-none transition duration-[0.2s]
              checked:border-pink-500 checked:bg-pink-400 checked:text-white hover:cursor-pointer dark:border-white/10`}
                value={field.value}
                onChange={field.onChange}
              />
              <label
                htmlFor={inputName}
                className={`w-fit cursor-pointer text-sm ${
                  field.value ? "text-darkGrey-900" : "text-secondaryGrey-700"
                } ${bold ? "font-bold" : ""}`}
              >
                {text}
              </label>
            </div>
          )}
        />
      ) : (
        <div className={`flex items-center ${label ? "mt-2" : ""}`}>
          <input
            type="checkbox"
            id={inputName}
            className={`defaultCheckbox relative me-2 h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none
              rounded-md border border-darkGrey-300 bg-white text-white/0 outline-none transition duration-[0.2s]
              checked:border-pink-500 checked:bg-pink-400 checked:text-white hover:cursor-pointer dark:border-white/10`}
            checked={value}
            onChange={onChange}
          />
          <label
            htmlFor={inputName}
            className={`w-fit cursor-pointer ${
              value ? "text-darkGrey-900" : "text-secondaryGrey-700"
            } ${bold && !!value ? "font-bold" : ""} ${
              bold ? "text-base" : "text-sm"
            }`}
          >
            {text}
          </label>
        </div>
      )}
    </div>
  );
}
