import { redirect } from "react-router-dom";
import { prepareAuth } from "./prepareAuth";

// If the user in NOT connected and tries to access app routes,
// we redirect to `/auth`

// export async function appLoader() {
//   const { userIsConnected } = await prepareAuth();
//   if (!userIsConnected) {
//     return redirect("/auth");
//   }
//   return null;
// }

// If the user is connected and tries to access auth routes,
// we redirect to `/`

export async function authLoader() {
  const { userIsConnected } = await prepareAuth();
  if (userIsConnected) {
    return redirect("/");
  }

  return null;
}
