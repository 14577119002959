import { HiAnnotation } from "react-icons/hi";
import { translate } from "ui/i18n";

export function Note({ note }: { note: string }) {
  return (
    <div className="space-y-3 rounded-[30px] bg-white p-6">
      <HiAnnotation size={46} color="#47548C" />
      <p className="text-xl font-bold text-secondaryGrey-900">
        {translate("ns1:Activities.Note")}
      </p>
      <div className="text-sm font-medium text-secondaryGrey-700">{note}</div>
    </div>
  );
}
