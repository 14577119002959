import { ActivityMaterial } from "domain/entities/activities.entities";
import { HiPaperClip } from "react-icons/hi";
import { translate } from "ui/i18n";
import { CustomMarkdown } from "ui/pages/app/components/CustomMarkdown";

interface IProps {
  materiels: ActivityMaterial[];
}

export function Materiels({ materiels }: IProps) {
  return (
    <div className="space-y-3 rounded-[30px] bg-white p-6">
      <HiPaperClip size={46} color="#47548C" />
      <p className="text-xl font-bold text-secondaryGrey-900">
        {translate("ns1:Activities.Equipment")}
      </p>
      <div className="text-sm font-medium text-secondaryGrey-700">
        {materiels?.map((material, index) => (
          <li key={"materiel" + index}>
            <CustomMarkdown>{material.title}</CustomMarkdown>
          </li>
        ))}
      </div>
    </div>
  );
}
