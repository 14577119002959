import { RadioGroup } from "@chakra-ui/react";
import { INCheckbox } from "ui/pages/app/components/INCheckbox";
import {
  contextOfUseFilters,
  modalitiesFilters,
  participantsFilters,
  typesFilters,
} from "ui/pages/app/components/activities/activitiesFiltersValues";
import { INRangeSlider } from "../INRangeSlider";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";
import { INRadioButton } from "../INRadioButton";
import { HiArrowLeft } from "react-icons/hi";
import { translate } from "ui/i18n";

interface IProps {
  filters: ActivitiesPageFilters;
  duration: DurationFilter;
  setDuration: (duration: DurationFilter) => void;
  setFilters: (
    filters: ActivitiesPageFilters & {
      [key: string]: string | string[] | boolean;
    }
  ) => void;
  fetchActivities?: ({
    duration,
  }: {
    duration?: { $gte: number; $lte: number };
  }) => void;
  activitiesType: "techniques" | "seances";
  maxDuration: number;
  minDuration: number;
  setIsFiltersOpen: (isFiltersOpen: boolean) => void;
}

export function MobileActivitiesFilters({
  filters,
  setFilters,
  duration,
  setDuration,
  fetchActivities,
  activitiesType,
  maxDuration,
  minDuration,
  setIsFiltersOpen,
}: IProps) {
  return (
    <div className="w-screen p-4">
      <button
        onClick={() => setIsFiltersOpen(false)}
        className="my-2.5 flex h-5 items-center"
      >
        <HiArrowLeft size={24} className="text-darkGrey-800" />
      </button>

      <div className="mt-8 flex flex-col space-y-8 rounded-[20px] bg-white p-6">
        {/* ContextOfUse filter */}
        {activitiesType !== "seances" && (
          <div>
            <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
              {translate("ns1:Activities.Buttons.ContextOfUse")}
            </div>
            <div className="flex flex-col space-y-4">
              <>
                {contextOfUseFilters.map((filter) => (
                  <INCheckbox
                    key={filter.value}
                    inputName={filter.value}
                    text={filter.text}
                    value={filters.contexts?.includes(filter.value)}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        contexts: filters.contexts?.includes(filter.value)
                          ? filters.contexts.filter((c) => c !== filter.value)
                          : [...filters.contexts, filter.value],
                      })
                    }
                    bold
                  />
                ))}
              </>
            </div>
          </div>
        )}

        {/* Type filter */}
        {activitiesType !== "seances" && (
          <div>
            <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
              {translate("ns1:Activities.Buttons.Type")}
            </div>
            <div className="flex flex-col space-y-4">
              <>
                {typesFilters.map((filter) => (
                  <INCheckbox
                    key={filter.value}
                    inputName={filter.value}
                    text={filter.text}
                    value={filters.types?.includes(filter.value)}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        types: filters.types?.includes(filter.value)
                          ? filters.types.filter((c) => c !== filter.value)
                          : [...filters.types, filter.value],
                      })
                    }
                    bold
                  />
                ))}
              </>
            </div>
          </div>
        )}

        {/* Duration filter */}
        <div>
          <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
            {translate("ns1:Activities.Buttons.Duration")}
          </div>
          <INRangeSlider
            min={minDuration}
            max={maxDuration}
            duration={duration}
            setDuration={setDuration}
            width="100%"
            step={activitiesType !== "seances" ? 5 : 30}
            fetchActivities={fetchActivities}
          />
        </div>

        {/* Participants filter */}
        <div>
          <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
            {translate("ns1:Activities.Buttons.Participants")}
          </div>
          <RadioGroup
            onChange={(value) =>
              setFilters({ ...filters, participants: value })
            }
            value={filters.participants}
            className="flex flex-col space-y-4"
          >
            {participantsFilters.map((filter) => (
              <INRadioButton
                key={filter.value}
                text={filter.text}
                value={filter.value}
                selectedValue={filters.participants}
              />
            ))}
          </RadioGroup>
        </div>

        {/* Modality filter */}
        <div>
          <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
            {translate("ns1:Activities.Buttons.Modality")}
          </div>
          <div className="flex flex-col space-y-4">
            {modalitiesFilters.map((filter) => (
              <INCheckbox
                key={filter.value}
                inputName={filter.value}
                text={filter.text}
                value={filters.modalities?.includes(filter.value)}
                onChange={() =>
                  setFilters({
                    ...filters,
                    modalities: filters.modalities?.includes(filter.value)
                      ? filters.modalities.filter((c) => c !== filter.value)
                      : [...filters.modalities, filter.value],
                  })
                }
                bold
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
