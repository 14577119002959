import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { translate } from "ui/i18n";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { useWindowWidth } from "ui/utils/hooks";
import { HiOutlineDocument, HiPencilAlt } from "react-icons/hi";
import { TechniqueContext } from "domain/entities/techniques.entities";
import { CloseButton } from "ui/pages/app/components/CloseButton";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  context: TechniqueContext;
  openChooseTechniquesModal: () => void;
  openCreatePersonalStepModal: () => void;
}

export function ModalChooseTechType({
  isOpen,
  onClose,
  context,
  openChooseTechniquesModal,
  openCreatePersonalStepModal,
}: IProps) {
  const isDesktop = useWindowWidth();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="750px" margin="20px" className="!rounded-[30px] p-8">
        <CloseButton onClick={onClose} />
        <div className="mt-12 flex flex-col items-center">
          <h4 className="w-fit text-center font-bold text-secondaryGrey-900">
            {translate(`ns1:CreateSeance.WhatToAdd.${context}`)}
          </h4>

          <div className="mt-16 w-full max-w-[600px]">
            <PrimaryButton
              type="submit"
              height={isDesktop ? "52px" : "46px"}
              width="100%"
              className="disabled bg-pink-400 hover:bg-pink-300 disabled:bg-pink-300 disabled:bg-opacity-20"
              onClick={openChooseTechniquesModal}
            >
              <HiOutlineDocument color="#fff" size={24} className="me-2" />
              {translate("ns1:CreateSeance.AddTechniques")}
            </PrimaryButton>

            <PrimaryButton
              height={isDesktop ? "52px" : "46px"}
              width="100%"
              className="mt-4 md:mt-6"
              onClick={openCreatePersonalStepModal}
            >
              <HiPencilAlt color="#fff" size={24} className="me-2" />
              {translate("ns1:CreateSeance.AddCustomStep")}
            </PrimaryButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
