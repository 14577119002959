import { store } from "domain/store";
import { router } from "ui/navigation/router";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ChakraProvider } from "@chakra-ui/react";
import { customChakraTheme } from "./chakra-ui-theme";

export function App() {
  return (
    <ReduxProvider store={store}>
      <ChakraProvider theme={customChakraTheme}>
        <Toaster position="top-right" reverseOrder={false} />
        <RouterProvider router={router} />
      </ChakraProvider>
    </ReduxProvider>
  );
}
