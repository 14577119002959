import { AuthGateway } from "domain/auth/auth.gateway";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "domain/store";
import { AuthHttpGateway } from "data/auth/auth-http-gateway";
import { TechniquesGateway } from "domain/techniques/techniques.gateway";
import { TechniquesHttpGateway } from "data/techniques/techniques-http-gateway";
import { SeancesGateway } from "domain/seances/seances.gateway";
import { SeancesHttpGateway } from "data/seances/seances-http-gateway";

export type ThunksExtraArgument = {
  authGateway: AuthGateway;
  techniquesGateway: TechniquesGateway;
  seancesGateway: SeancesGateway;
};

const authGateway: AuthGateway = new AuthHttpGateway();
const techniquesGateway: TechniquesGateway = new TechniquesHttpGateway();
const seancesGateway: SeancesGateway = new SeancesHttpGateway();

export const thunkExtraArgument: ThunksExtraArgument = {
  authGateway,
  techniquesGateway,
  seancesGateway,
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  extra: ThunksExtraArgument;
}>();
