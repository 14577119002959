export function CardBgPink300({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5323)">
        <rect width="280" height="164" rx="20" fill="#EC7598" />
        <g clipPath="url(#clip1_77_5323)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 104.778C85.2285 104.778 130 145.57 130 195.889C130 246.208 85.2285 287 30 287C-25.2285 287 -70 246.208 -70 195.889C-70 145.57 -25.2285 104.778 30 104.778ZM30 86.5555C96.2742 86.5555 150 135.506 150 195.889C150 256.272 96.2742 305.222 30 305.222C-36.2742 305.222 -90 256.272 -90 195.889C-90 135.506 -36.2742 86.5555 30 86.5555Z"
            fill="#F8AEBC"
          />
          <path
            d="M2.15202 51.5906C-2.90251 48.9318 -4.52184 42.8656 -1.46488 38.0414L0.94009 34.2462C3.99705 29.422 10.5727 27.6667 15.6272 30.3255L20.6629 32.9744C25.7175 35.6333 27.3368 41.6994 24.2798 46.5236L21.8749 50.3189C18.8179 55.143 12.2422 56.8984 7.18772 54.2395L2.15202 51.5906Z"
            fill="#E14277"
          />
          <path
            d="M104.034 124.321C98.555 125.932 92.5675 122.873 90.6608 117.49L85.4524 102.784C83.5457 97.4006 86.4419 91.7305 91.9211 90.1196L109.617 84.9169C115.096 83.306 121.084 86.3642 122.99 91.7477L128.199 106.453C130.105 111.837 127.209 117.507 121.73 119.118L104.034 124.321Z"
            fill="#E14277"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_5323">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_5323">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="matrix(1 0 0 -1 -120 195.889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
