export function CardBgPink200({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5706)">
        <rect width="80" height="80" rx="8" fill="#F8AEBC" />
        <g clipPath="url(#clip1_266_5706)">
          <rect
            x="26.3999"
            y="51.3584"
            width="11.5522"
            height="11.8731"
            rx="5.77612"
            transform="rotate(-29.8967 26.3999 51.3584)"
            fill="#EC7598"
          />
          <rect
            x="5.2002"
            y="45.7583"
            width="7.78379"
            height="8"
            rx="3.89189"
            transform="rotate(-29.8967 5.2002 45.7583)"
            fill="#EC7598"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.1272 69.1935C39.5505 72.372 46 77.1833 46 84.8004C46 87.0096 44.2091 88.8004 42 88.8004C39.7909 88.8004 38 87.0096 38 84.8004C38 82.3177 35.8745 79.4789 29.5791 76.3636C23.6105 73.4102 15.5493 71.0332 7.18908 68.6752C6.52287 68.4873 5.85407 68.2993 5.18419 68.1111C-2.32208 66.0016 -9.96291 63.8544 -15.5921 61.3028C-18.6412 59.9208 -21.5455 58.2321 -23.5374 56.0299C-25.703 53.6356 -26.8767 50.4855 -25.8444 46.895C-24.0948 40.8094 -19.0928 38.3685 -14.1513 37.4928C-9.51141 36.6706 -3.96381 37.0225 0.917191 37.3322C1.05446 37.3409 1.19121 37.3496 1.32739 37.3582C6.63653 37.694 11.133 37.9407 14.4816 37.2715C16.0901 36.9501 17.0883 36.4746 17.6937 35.9684C18.2131 35.5341 18.6552 34.9084 18.8569 33.727C18.945 33.2109 18.8888 32.7012 18.065 31.8654C17.0953 30.8815 15.3433 29.7928 12.6341 28.6404C8.28461 26.7902 2.59945 25.2168 -3.72124 23.4675C-5.29141 23.0329 -6.9008 22.5875 -8.53868 22.1243C-16.5081 19.8704 -25.0621 17.2117 -31.6468 13.3549C-38.2506 9.48683 -43.8001 3.83481 -43.8001 -4.59972C-43.8001 -6.80886 -42.0092 -8.59972 -39.8001 -8.59972C-37.5909 -8.59971 -35.8001 -6.80884 -35.8001 -4.59971C-35.8001 -0.334253 -33.1746 3.18872 -27.6035 6.45188C-22.0131 9.72631 -14.3922 12.1551 -6.36158 14.4262C-4.87546 14.8465 -3.36941 15.2627 -1.86314 15.6789C4.50483 17.4385 10.877 19.1992 15.7656 21.2788C18.7939 22.567 21.692 24.1486 23.7628 26.2498C25.9797 28.4992 27.3547 31.4895 26.7428 35.0734C26.2445 37.992 24.9054 40.3663 22.8255 42.1055C20.8317 43.7727 18.4127 44.6441 16.0494 45.1164C11.5273 46.0201 5.96815 45.6681 1.05979 45.3573L0.822319 45.3422C-4.47424 45.0072 -9.13187 44.728 -12.7553 45.3701C-16.2075 45.9819 -17.6055 47.191 -18.1559 49.1054C-18.2736 49.5149 -18.3098 49.8836 -17.6043 50.6635C-16.7251 51.6355 -15.0247 52.7766 -12.2894 54.0164C-7.24648 56.3022 -0.197471 58.2857 7.53505 60.4614C8.13966 60.6316 8.74845 60.8029 9.36079 60.9756C17.5756 63.2926 26.3769 65.8532 33.1272 69.1935Z"
            fill="#FFD3DC"
            fill-opacity="0.996078"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_266_5706">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_266_5706">
          <rect
            width="120"
            height="100"
            fill="white"
            transform="translate(-60 -10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
