import { HiOutlineMenu, HiSearch } from "react-icons/hi";
import { useWindowWidth } from "ui/utils/hooks";
import { INSearchField } from "ui/pages/app/components/INSearchField";
import { translate } from "ui/i18n";
import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "ui/pages/layouts/components/SidebarContext";

interface IProps {
  search?: string;
  setSearch?: (value: string) => void;
  title: string;
  placeholder?: "TechniqueName";
}

export function PageHeader({ search, setSearch, title, placeholder }: IProps) {
  const isDesktop = useWindowWidth();
  const { setIsSidebarOpen } = useContext(SidebarContext);
  const [tempSearch, setTempSearch] = useState("");

  useEffect(() => {
    if (typeof search === "string" && setSearch) {
      const timeout = setTimeout(() => {
        setSearch(tempSearch);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [tempSearch]); //eslint-disable-line

  return (
    <div className="flex h-[52px] items-center justify-between">
      {!isDesktop && (
        <button onClick={() => setIsSidebarOpen(true)}>
          <HiOutlineMenu size={34} />
        </button>
      )}
      <h1 className="text-lg md:text-3xl">{title}</h1>
      {typeof search === "string" && setSearch && (
        <>
          {isDesktop ? (
            <INSearchField
              placeholder={translate(`ns1:Home.${placeholder || "Keywords"}`)}
              value={tempSearch}
              setValue={setTempSearch}
            />
          ) : (
            <button className="flex h-10 w-10 items-center justify-center rounded-xl border border-darkGrey-200 text-secondaryGrey-900 disabled:border-none disabled:text-secondaryGrey-600">
              <HiSearch size={20} color="#2D396B" />
            </button>
          )}
        </>
      )}
    </div>
  );
}
