import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from "@chakra-ui/react";
import { translate } from "ui/i18n";
import { getDurationInHours } from "ui/utils/formatters";

interface IProps {
  min: number;
  max: number;
  duration: { $gte: number; $lte: number };
  setDuration: (duration: { $gte: number; $lte: number }) => void;
  width?: number | string;
  step?: number;
  fetchActivities?: ({
    duration,
  }: {
    duration?: { $gte: number; $lte: number };
  }) => void;
  resetButton?: boolean;
}

export function INRangeSlider({
  min,
  max,
  duration,
  setDuration,
  width = 200,
  step = 5,
  fetchActivities,
  resetButton,
}: IProps) {
  const resetSlider = () => {
    setDuration({ $gte: min, $lte: max });
    fetchActivities({ duration: { $gte: min, $lte: max } });
  };

  return (
    <div className="flex flex-col">
      <span className="mb-4 text-base font-medium text-darkGrey-700">
        {`${getDurationInHours(duration.$gte)} - ${getDurationInHours(
          duration.$lte
        )}`}
      </span>
      <RangeSlider
        aria-label={["min", "max"]} //eslint-disable-line
        defaultValue={[duration.$gte, duration.$lte]}
        value={[duration.$gte, duration.$lte]}
        width={width}
        min={min}
        max={max}
        step={step}
        onChangeEnd={(values) =>
          fetchActivities({ duration: { $gte: values[0], $lte: values[1] } })
        }
        onChange={(values) => setDuration({ $gte: values[0], $lte: values[1] })}
      >
        <RangeSliderTrack bg="#e1e9f8" height="8px">
          <RangeSliderFilledTrack bg="#e14277" />
        </RangeSliderTrack>
        <RangeSliderThumb
          index={0}
          style={{
            backgroundColor: "#e14277",
            border: "2px solid white",
            boxShadow: "none",
          }}
        />
        <RangeSliderThumb
          index={1}
          style={{
            backgroundColor: "#e14277",
            border: "2px solid white",
            boxShadow: "none",
          }}
        />
      </RangeSlider>
      {resetButton && (
        <button
          className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
          onClick={resetSlider}
        >
          {translate("ns1:Button.Reset")}
        </button>
      )}
    </div>
  );
}
