export function CardBgGrey400({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5719)">
        <rect width="80" height="80" rx="8" fill="#657F91" />
        <g clipPath="url(#clip1_266_5719)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-25.9168 -2.70515C-24.8646 -4.64763 -22.437 -5.36936 -20.4945 -4.31718C-10.3197 1.19419 -0.836559 9.44508 6.0769 21.597C12.9825 33.7351 17.2 49.5454 17.2 70C17.2 72.2091 15.4091 74 13.2 74C10.9908 74 9.19999 72.2091 9.19999 70C9.19999 50.6545 5.21766 36.2649 -0.876543 25.553C-6.96288 14.8549 -15.2796 7.60579 -24.3048 2.71715C-26.2472 1.66498 -26.969 -0.76267 -25.9168 -2.70515Z"
            fill="#9CBBCF"
          />
          <path
            d="M59.7331 37.7922C59.3212 35.6218 57.2279 34.1962 55.0575 34.6081L47.1978 36.0998C45.0274 36.5117 43.6018 38.6051 44.0137 40.7755C44.4257 42.9459 46.519 44.3714 48.6894 43.9595L56.5491 42.4678C58.7195 42.0559 60.1451 39.9626 59.7331 37.7922Z"
            fill="#7A97AA"
          />
          <path
            d="M39.4292 58.9701C39.9925 61.1062 42.1809 62.3812 44.317 61.8178C46.4531 61.2545 47.728 59.0661 47.1646 56.93C46.6013 54.7939 44.4129 53.519 42.2768 54.0823C40.1407 54.6457 38.8658 56.834 39.4292 58.9701Z"
            fill="#7A97AA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.0001 39.1997C33.2093 39.1997 35.0001 40.9906 35.0001 43.1997C35.0001 44.6939 34.4467 47.4203 33.6824 50.2735C32.8697 53.3075 31.6735 57.0118 30.1264 60.657C28.5933 64.2691 26.639 68.007 24.2503 70.9804C21.9271 73.8723 18.7066 76.6392 14.5479 77.0694C10.8732 77.4495 6.95515 76.1859 3.54459 74.6064C0.0238113 72.9758 -3.51416 70.7404 -6.61083 68.5491C-9.72442 66.3458 -12.4879 64.1184 -14.4679 62.4475C-15.4604 61.6099 -16.2622 60.907 -16.8196 60.41C-17.0984 60.1614 -17.3164 59.964 -17.467 59.8267C-17.5423 59.758 -17.6007 59.7043 -17.6415 59.6667L-17.6894 59.6225L-17.709 59.6043C-17.7096 59.6038 -17.7101 59.6033 -14.9848 56.6753L-17.7101 59.6033C-19.3272 58.0982 -19.4179 55.5671 -17.9128 53.9501C-16.4079 52.3332 -13.8773 52.2422 -12.2602 53.7468L-12.2597 53.7473L-12.253 53.7534L-12.2198 53.7841C-12.1888 53.8127 -12.1406 53.857 -12.0757 53.9161C-11.9461 54.0344 -11.7505 54.2116 -11.4954 54.439C-10.9851 54.894 -10.2385 55.5487 -9.30845 56.3336C-7.44323 57.9076 -4.86446 59.9845 -1.98977 62.0187C0.901854 64.0649 3.99817 66.0001 6.90663 67.3471C9.92531 68.7452 12.2315 69.2663 13.7248 69.1118C14.7341 69.0074 16.2057 68.2206 18.0135 65.9702C19.7558 63.8014 21.3714 60.8084 22.7622 57.5314C24.139 54.2876 25.2207 50.9446 25.9549 48.2036C26.3214 46.8353 26.5936 45.6459 26.771 44.7207C26.8598 44.2575 26.9208 43.8813 26.9584 43.5954C26.9904 43.3521 26.9979 43.2302 26.9996 43.2019C27.0001 43.1939 27.0001 43.1934 27.0001 43.1997C27.0001 40.9906 28.791 39.1997 31.0001 39.1997Z"
            fill="#9CBBCF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_266_5719">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_266_5719">
          <rect
            width="120"
            height="100"
            fill="white"
            transform="translate(-60 -10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
