import { useNavigate } from "react-router-dom";
import { LogoInnizio } from "ui/assets/svg/LogoInnizio";
import { BackButton } from "ui/pages/app/components/BackButton";

interface IProps {
  children?: React.ReactNode;
}

export function AuthLayout({ children }: IProps) {
  const navigate = useNavigate();

  return (
    <div className="relative min-h-dvh w-full bg-white">
      <div className="flex min-h-dvh w-full flex-col items-center justify-between p-4">
        <div className="h-5 self-start md:ml-8 md:mt-8">
          <BackButton onClick={() => navigate(-1)} />
        </div>
        {children}
        <div className="mb-4 mt-12 flex w-full justify-center md:mb-8">
          <LogoInnizio />
        </div>
      </div>
    </div>
  );
}
