import { Technique, TechniqueType } from "domain/entities/techniques.entities";
import { HiCheck, HiDownload, HiOutlineHeart, HiThumbUp } from "react-icons/hi";
import { BannerGrey400 } from "ui/assets/svg/technique-banners-desktop/BannerGrey400";
import { BannerLightGreen400 } from "ui/assets/svg/technique-banners-desktop/BannerLightGreen400";
import { BannerLightGreen500 } from "ui/assets/svg/technique-banners-desktop/BannerLightGreen500";
import { BannerPaleGreen200 } from "ui/assets/svg/technique-banners-desktop/BannerPaleGreen200";
import { BannerPaleGreen400 } from "ui/assets/svg/technique-banners-desktop/BannerPaleGreen400";
import { BannerPink200 } from "ui/assets/svg/technique-banners-desktop/BannerPink200";
import { BannerPink300 } from "ui/assets/svg/technique-banners-desktop/BannerPink300";
import { BannerPink400 } from "ui/assets/svg/technique-banners-desktop/BannerPink400";
import { BannerPink500 } from "ui/assets/svg/technique-banners-desktop/BannerPink500";
import { CardBgGrey400 } from "ui/assets/svg/technique-card-backgrounds/CardBgGrey400";
import { CardBgLightGreen400 } from "ui/assets/svg/technique-card-backgrounds/CardBgLightGreen400";
import { CardBgLightGreen500 } from "ui/assets/svg/technique-card-backgrounds/CardBgLightGreen500";
import { CardBgPaleGreen200 } from "ui/assets/svg/technique-card-backgrounds/CardBgPaleGreen200";
import { CardBgPaleGreen400 } from "ui/assets/svg/technique-card-backgrounds/CardBgPaleGreen400";
import { CardBgPink200 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink200";
import { CardBgPink300 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink300";
import { CardBgPink400 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink400";
import { CardBgPink500 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink500";
import { useWindowWidth } from "ui/utils/hooks";

export function TechniqueBanner({
  technique,
  checkIcon,
  selected,
}: {
  technique: Technique;
  checkIcon?: boolean;
  selected?: boolean;
}) {
  const isDesktop = useWindowWidth();
  const bannerInfos = getBannerImage(
    technique.type as TechniqueType,
    isDesktop
  );
  return (
    <div className="activity-banner relative w-full">
      <bannerInfos.image width="100%" height="100%" />
      {isDesktop && !checkIcon && (
        <div className="absolute right-6 top-6 hidden space-x-4 md:flex">
          <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
            <HiOutlineHeart className="text-secondaryGrey-900" size={20} />
          </button>
          <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
            <HiDownload className="text-secondaryGrey-900" size={20} />
          </button>
        </div>
      )}
      {isDesktop && checkIcon && selected && (
        <div className="absolute right-6 top-6 hidden space-x-4 md:flex">
          <div className="flex h-8 w-8 items-center justify-center rounded-full border border-darkGrey-900 bg-darkGrey-700">
            <HiCheck color="#fff" size={26} />
          </div>
        </div>
      )}
      <div
        className={`items-bottom absolute bottom-6 flex w-full space-x-6 px-6 text-2xl ${bannerInfos.color}`}
      >
        <span className="font-bold">{technique.title}</span>
        <span className="flex items-end">
          <HiThumbUp size={28} className="me-2" />
          <span className="font-medium">12</span>
        </span>
      </div>
    </div>
  );
}

function getBannerImage(activityType: TechniqueType, isDesktop: boolean) {
  switch (activityType) {
    case "ice_breaker":
      return {
        image: isDesktop ? BannerLightGreen500 : CardBgLightGreen500,
        color: "text-secondaryGrey-900",
      };
    case "problematique":
      return {
        image: isDesktop ? BannerPink500 : CardBgPink500,
        color: "text-white",
      };
    case "equipes":
      return {
        image: isDesktop ? BannerPaleGreen400 : CardBgPaleGreen400,
        color: "text-white",
      };
    case "brainstorming":
      return {
        image: isDesktop ? BannerPink300 : CardBgPink300,
        color: "text-secondaryGrey-900",
      };
    case "projects":
      return {
        image: isDesktop ? BannerPaleGreen200 : CardBgPaleGreen200,
        color: "text-secondaryGrey-900",
      };
    case "pratiques":
      return {
        image: isDesktop ? BannerPink200 : CardBgPink200,
        color: "text-secondaryGrey-900",
      };
    case "evaluation":
      return {
        image: isDesktop ? BannerPink400 : CardBgPink400,
        color: "text-white",
      };
    case "pause":
      return {
        image: isDesktop ? BannerLightGreen400 : CardBgLightGreen400,
        color: "text-secondaryGrey-900",
      };
    case "vote":
      return {
        image: isDesktop ? BannerGrey400 : CardBgGrey400,
        color: "text-white",
      };
    default:
      return {
        image: isDesktop ? BannerLightGreen500 : CardBgPink500,
        color: "text-secondaryGrey-900",
      };
  }
}
