import { configureStore } from "@reduxjs/toolkit";
import { thunkExtraArgument } from "domain/store.thunk";
import { authSlice } from "domain/auth/auth.slice";
import { techniquesSlice } from "domain/techniques/techniques.slice";
import { seancesSlice } from "domain/seances/seances.slice";

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [techniquesSlice.name]: techniquesSlice.reducer,
    [seancesSlice.name]: seancesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument: thunkExtraArgument,
      },
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
