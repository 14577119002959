export function BannerPink200({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8852)">
        <rect width="1080" height="210" fill="#F8AEBC" />
        <g clipPath="url(#clip1_779_8852)">
          <rect
            x="846"
            y="133.395"
            width="28.8806"
            height="29.6828"
            rx="14.4403"
            transform="rotate(-29.8967 846 133.395)"
            fill="#EC7598"
          />
          <rect
            x="793"
            y="119.395"
            width="19.4595"
            height="20"
            rx="9.72973"
            transform="rotate(-29.8967 793 119.395)"
            fill="#EC7598"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M862.818 177.983C878.876 185.929 895 197.957 895 217C895 222.523 890.523 227 885 227C879.477 227 875 222.523 875 217C875 210.793 869.686 203.696 853.948 195.908C839.026 188.524 818.873 182.582 797.973 176.687C796.307 176.217 794.635 175.747 792.96 175.277C774.195 170.003 755.093 164.635 741.02 158.256C733.397 154.801 726.136 150.579 721.157 145.074C715.742 139.088 712.808 131.213 715.389 122.236C719.763 107.023 732.268 100.92 744.622 98.7311C756.221 96.6754 770.09 97.5553 782.293 98.3295C782.636 98.3513 782.978 98.3729 783.318 98.3945C796.591 99.2341 807.833 99.8508 816.204 98.1778C820.225 97.3742 822.721 96.1854 824.234 94.9199C825.533 93.8342 826.638 92.2701 827.142 89.3166C827.363 88.0262 827.222 86.7521 825.163 84.6626C822.738 82.2027 818.358 79.4811 811.585 76.6001C800.712 71.9746 786.499 68.041 770.697 63.6677C766.771 62.5813 762.748 61.4677 758.653 60.3097C738.73 54.6751 717.345 48.0283 700.883 38.3863C684.373 28.7161 670.5 14.5861 670.5 -6.50028C670.5 -12.0231 674.977 -16.5003 680.5 -16.5003C686.023 -16.5002 690.5 -12.0231 690.5 -6.50024C690.5 4.16339 697.064 12.9708 710.991 21.1287C724.967 29.3148 744.02 35.3867 764.096 41.0645C767.811 42.1153 771.576 43.1557 775.342 44.1962C791.262 48.5952 807.192 52.9971 819.414 58.1959C826.985 61.4164 834.23 65.3706 839.407 70.6236C844.949 76.2469 848.387 83.7229 846.857 92.6825C845.611 99.979 842.263 105.915 837.064 110.263C832.079 114.431 826.032 116.609 820.123 117.79C808.818 120.049 794.92 119.169 782.649 118.392L782.056 118.355C768.814 117.517 757.17 116.819 748.112 118.424C739.481 119.954 735.986 122.976 734.61 127.763C734.316 128.786 734.226 129.708 735.989 131.658C738.187 134.088 742.438 136.94 749.277 140.04C761.884 145.755 779.506 150.713 798.838 156.153C800.349 156.578 801.871 157.006 803.402 157.438C823.939 163.231 845.942 169.632 862.818 177.983Z"
            fill="#FFD3DC"
            fill-opacity="0.996078"
          />
          <rect
            x="710"
            y="3.39526"
            width="28.8806"
            height="29.6828"
            rx="14.4403"
            transform="rotate(-29.8967 710 3.39526)"
            fill="#EC7598"
          />
          <rect
            x="715"
            y="60.3953"
            width="19.4595"
            height="20"
            rx="9.72973"
            transform="rotate(-29.8967 715 60.3953)"
            fill="#EC7598"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8852">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8852">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(630 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
