export function ProgressionBar({ percentage }: { percentage: number }) {
  return (
    <div className="h-2 w-full rounded-[20px] bg-darkGrey-200">
      <div
        className="h-2 rounded-[20px] bg-darkGrey-600"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
}
