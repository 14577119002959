export function CardBgLightGreen500({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1254)">
        <rect width="280" height="164" rx="20" fill="#53BBAD" />
        <g clipPath="url(#clip1_1_1254)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-45.0757 -24.078C-48.8005 -27.7933 -55.1256 -28.054 -59.2034 -24.6604C-67.7329 -17.5618 -75.5619 -6.88064 -81.6263 4.04378C-87.7259 15.0315 -92.5211 27.1343 -94.4411 37.5521C-95.3936 42.7197 -95.7426 48.0139 -94.8236 52.7592C-93.9131 57.4611 -91.4477 62.9564 -85.4824 66.1676C-79.5492 69.3615 -72.7756 68.8785 -66.7949 67.1129C-60.75 65.3284 -54.0097 61.8302 -46.5373 56.8269C-21.0824 39.783 2.59782 38.2371 17.6208 44.1913C31.9985 49.8898 40.9096 63.3474 36.168 82.788C31.2101 103.115 29.0806 118.777 29.883 130.561C30.6842 142.327 34.5813 152.08 43.8037 157.747C52.5375 163.114 63.4555 163.109 73.51 161.765C83.8323 160.385 95.7969 157.198 108.261 153.791C125.797 148.998 138.115 152.522 145.022 158.015C152.052 163.607 154.632 171.978 151.745 179.87C149.998 184.644 152.83 189.804 158.069 191.395C163.309 192.986 168.972 190.406 170.718 185.633C176.24 170.541 171.079 154.553 158.175 144.288C145.148 133.926 125.34 130.098 102.514 136.337C89.751 139.826 79.2223 142.584 70.6067 143.736C61.7232 144.923 57.2585 144.036 54.9868 142.64C53.2038 141.544 50.4726 138.654 49.8446 129.433C49.2179 120.228 50.8585 106.557 55.6917 86.7409C62.3252 59.5436 49.5202 36.9627 25.5988 27.4817C2.32268 18.2563 -28.8792 22.3898 -58.381 42.1433C-65.0343 46.5982 -69.7993 48.845 -72.9596 49.778C-73.8593 50.0436 -74.5183 50.1733 -74.9789 50.2341C-75.0279 50.0584 -75.0788 49.8464 -75.1278 49.5929C-75.4966 47.6888 -75.4744 44.6776 -74.7173 40.5697C-73.2183 32.4363 -69.2412 22.1072 -63.7793 12.2681C-58.2822 2.36581 -51.7607 -6.17457 -45.7149 -11.2062C-41.6371 -14.5998 -41.351 -20.3628 -45.0757 -24.078ZM-74.6815 50.9817C-74.6805 50.9802 -74.6911 50.9618 -74.7163 50.9306C-74.6951 50.9676 -74.6824 50.9832 -74.6815 50.9817ZM-75.8128 50.2762C-75.8459 50.2689 -75.8638 50.2671 -75.8644 50.268C-75.865 50.269 -75.8484 50.2727 -75.8128 50.2762Z"
            fill="#9BECE1"
          />
          <path
            d="M141.267 61.1324C146.746 62.7434 152.733 59.6851 154.64 54.3016L159.848 39.5959C161.755 34.2124 158.859 28.5423 153.38 26.9313L135.684 21.7287C130.205 20.1177 124.217 23.176 122.311 28.5595L117.102 43.2652C115.196 48.6487 118.092 54.3188 123.571 55.9298L141.267 61.1324Z"
            fill="#68D8C9"
          />
          <path
            d="M104.511 87.2893C109.566 84.6305 111.185 78.5643 108.128 73.7402L105.723 69.9449C102.666 65.1208 96.0904 63.3654 91.0359 66.0242L86.0001 68.6732C80.9456 71.332 79.3263 77.3982 82.3832 82.2223L84.7882 86.0176C87.8452 90.8418 94.4208 92.5971 99.4754 89.9383L104.511 87.2893Z"
            fill="#68D8C9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_1254">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_1_1254">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="translate(-120 -31.8887)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
