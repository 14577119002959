export function CardBgPink400({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5892)">
        <rect width="280" height="164" rx="20" fill="#E14277" />
        <g clipPath="url(#clip1_77_5892)">
          <rect
            width="18.439"
            height="19.8003"
            rx="9.21952"
            transform="matrix(0.370974 -0.928643 0.949171 0.314761 100.194 147.91)"
            fill="#EC7598"
          />
          <rect
            width="32.2683"
            height="34.6505"
            rx="16.1342"
            transform="matrix(0.370974 -0.928643 0.949171 0.314761 57.9785 39.9622)"
            fill="#EC7598"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.0017 -27.0491C77.1415 -22.4102 74.7487 -17.0692 69.6572 -15.1196C64.1327 -13.0043 51.6836 -7.13919 41.2352 2.82038C30.96 12.615 22.9594 25.9761 24.6075 43.8019C26.2741 61.8272 37.1343 76.0999 49.9477 86.3234C51.7725 87.7793 53.623 89.1411 55.473 90.4076C61.6503 83.821 68.8741 78.7102 76.2259 75.7518C86.2132 71.733 97.126 71.4776 105.903 74.9412C114.983 78.5239 122.042 86.4455 120.997 97.1796C120.042 106.996 113.533 113.746 105.282 117.365C97.4342 120.808 88.0066 121.525 79.1873 120.504C74.31 119.939 68.1701 118.024 61.8074 115.225C60.0766 118.876 58.776 122.807 58.0886 126.92C55.5197 142.29 61.4496 161.301 90.2928 177.373C95.0044 179.998 96.488 185.607 93.6065 189.899C90.725 194.192 84.5696 195.544 79.858 192.919C44.9594 173.473 34.3979 147.622 38.3165 124.177C39.4001 117.693 41.5903 111.471 44.5822 105.735C41.9661 103.989 39.3474 102.087 36.7716 100.032C21.4226 87.7857 6.91089 69.4808 4.67811 45.3315C2.42689 20.9829 13.6706 2.72321 26.7774 -9.77042C39.711 -22.0991 54.7816 -29.1897 61.9084 -31.9186C66.9999 -33.8682 72.862 -31.6881 75.0017 -27.0491ZM73.0527 99.8828C77.0631 101.473 80.0838 102.239 81.7083 102.427C87.9501 103.15 93.222 102.431 96.606 100.947C99.5865 99.6392 100.842 97.9715 101.075 95.5697C101.22 94.0856 100.67 92.734 97.9549 91.6625C94.9379 90.472 89.8708 90.1815 84.3061 92.4207C80.631 93.8996 76.7188 96.472 73.0527 99.8828Z"
            fill="#F8AEBC"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_5892">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_5892">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="translate(-120 -31.8889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
