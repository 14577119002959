import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import { useWindowWidth } from "ui/utils/hooks";
import { useCallback, useRef, useState } from "react";
import { TechniqueCard } from "ui/pages/app/components/activities/TechniqueCard";
import { Technique } from "domain/entities/techniques.entities";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import { selectIsTechniquesLoading } from "domain/techniques/techniques.selectors";
import { useAppSelector } from "ui/hook/store";
import { useNavigate } from "react-router-dom";

interface IProps {
  title: string;
  techniques: Technique[];
}

export function HomeCardsCarousel({ title, techniques }: IProps) {
  const sliderRef = useRef(null);
  const divRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const isDesktop = useWindowWidth();
  const isLoading = useAppSelector(selectIsTechniquesLoading);
  const navigate = useNavigate();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const resetDisabledButtons = (e: any) => {
    setIsPrevDisabled(e.isBeginning);
    setIsNextDisabled(e.isEnd);
  };

  return (
    <div className="mt-6 w-full md:mt-10" ref={divRef}>
      <div className="flex w-full justify-between">
        <h2 className="text-xl md:text-2xl">{title}</h2>
        {isDesktop && (
          <div className="flex space-x-2">
            <button
              className="flex h-9 w-9 items-center justify-center rounded-xl border border-darkGrey-200 text-secondaryGrey-900 disabled:border-none disabled:text-secondaryGrey-600"
              onClick={handlePrev}
              disabled={isPrevDisabled}
            >
              <HiChevronLeft />
            </button>
            <button
              className="flex h-9 w-9 items-center justify-center rounded-xl border border-darkGrey-200 text-secondaryGrey-900 disabled:border-none disabled:text-secondaryGrey-600"
              onClick={handleNext}
              disabled={isNextDisabled}
            >
              <HiChevronRight />
            </button>
          </div>
        )}
      </div>
      <Swiper
        ref={sliderRef}
        onSlideChange={resetDisabledButtons}
        className="mt-4 md:mt-8"
        breakpoints={{
          0: {
            slidesPerGroup: 2,
            slidesPerView: 2,
            spaceBetween: 10,
          },
          992: {
            slidesPerGroup: 3,
            slidesPerView: 3,
            spaceBetween: 15,
          },
          1200: {
            slidesPerGroup: 4,
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }}
        freeMode={!isDesktop}
        modules={[FreeMode]}
      >
        <div className="flex-1">
          {!isLoading ? (
            <>
              {techniques.map((technique, index) => (
                <SwiperSlide key={title + index} className="w-full">
                  <TechniqueCard
                    technique={technique}
                    key={title + index}
                    onClick={() => navigate(`/technique/${technique?.id}`)}
                  />
                </SwiperSlide>
              ))}
            </>
          ) : (
            <>
              {[...Array(10)].map((_, index) => (
                <SwiperSlide
                  key={title + "skeleton" + index}
                  className="w-full"
                >
                  <INSkeleton />
                </SwiperSlide>
              ))}
            </>
          )}
        </div>
      </Swiper>
    </div>
  );
}
