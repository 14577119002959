export function CardBgGrey300({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_76_4839)">
        <rect width="280" height="164" rx="20" fill="#7A97AA" />
        <g clipPath="url(#clip1_76_4839)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 141.222C60.3757 141.222 85 163.658 85 191.333C85 219.009 60.3757 241.444 30 241.444C-0.37566 241.444 -25 219.009 -25 191.333C-25 163.658 -0.37566 141.222 30 141.222ZM30 123C71.4214 123 105 153.594 105 191.333C105 229.073 71.4214 259.667 30 259.667C-11.4214 259.667 -45 229.073 -45 191.333C-45 153.594 -11.4214 123 30 123Z"
            fill="#9CBBCF"
          />
          <path
            d="M2.15202 51.5906C-2.90251 48.9318 -4.52184 42.8656 -1.46488 38.0414L0.940089 34.2462C3.99705 29.422 10.5727 27.6667 15.6272 30.3255L20.6629 32.9744C25.7175 35.6333 27.3368 41.6994 24.2798 46.5236L21.8749 50.3189C18.8179 55.143 12.2422 56.8984 7.18772 54.2395L2.15202 51.5906Z"
            fill="#657F91"
          />
          <path
            d="M104.034 124.32C98.555 125.931 92.5675 122.873 90.6608 117.49L85.4524 102.784C83.5457 97.4004 86.4419 91.7303 91.9211 90.1193L109.617 84.9167C115.096 83.3057 121.084 86.364 122.99 91.7475L128.199 106.453C130.105 111.837 127.209 117.507 121.73 119.118L104.034 124.32Z"
            fill="#657F91"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_76_4839">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_76_4839">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="matrix(1 0 0 -1 -120 195.889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
