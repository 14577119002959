import { useNavigate, useRouteError } from "react-router-dom";
import { translate } from "ui/i18n";
import { formatError } from "ui/pages/error/formatError";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";

export function Error() {
  const error = useRouteError();
  const errorMessage = formatError(error);
  const navigate = useNavigate();

  return (
    <div className="mt-12 flex flex-col items-center justify-center">
      <h4 className="font-bold">{translate("ns1:Errors.Error")}</h4>
      <p>
        <i>{errorMessage}</i>
      </p>

      <PrimaryButton onClick={() => navigate("/")} className="mt-6">
        {translate("ns1:Routes.Home")}
      </PrimaryButton>
    </div>
  );
}
