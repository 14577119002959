import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RegistrationStep1 } from "ui/pages/auth/register/RegistrationStep1";
import { RegistrationStep2 } from "ui/pages/auth/register/RegistrationStep2";

export function Register() {
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email;
  const [step, setStep] = useState(2);

  useEffect(() => {
    if (!email) navigate("/auth");
  }, [email]); //eslint-disable-line

  return (
    <>
      {step === 1 && <RegistrationStep1 email={email} setStep={setStep} />}
      {step === 2 && <RegistrationStep2 email={email} />}
    </>
  );
}
