export function BannerGrey300({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8876)">
        <rect width="1080" height="210" fill="#7A97AA" />
        <g clipPath="url(#clip1_779_8876)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M826 40C856.376 40 881 15.3757 881 -15C881 -45.3757 856.376 -70 826 -70C795.624 -70 771 -45.3757 771 -15C771 15.3757 795.624 40 826 40ZM826 60C867.421 60 901 26.4214 901 -15C901 -56.4214 867.421 -90 826 -90C784.579 -90 751 -56.4214 751 -15C751 26.4214 784.579 60 826 60Z"
            fill="#9CBBCF"
          />
          <path
            d="M797.956 138.49C793.01 141.345 791.315 147.67 794.171 152.616L797.305 158.044C800.16 162.99 806.485 164.685 811.431 161.829L816.859 158.695C821.805 155.84 823.5 149.515 820.644 144.569L817.51 139.141C814.655 134.194 808.33 132.5 803.384 135.355L797.956 138.49Z"
            fill="#657F91"
          />
          <path
            d="M899.955 58.5248C894.519 56.7709 888.691 59.7552 886.938 65.1904L881.176 83.0456C879.422 88.4808 882.406 94.3087 887.841 96.0626L905.697 101.824C911.132 103.578 916.96 100.594 918.714 95.1587L924.475 77.3035C926.229 71.8683 923.245 66.0404 917.81 64.2865L899.955 58.5248Z"
            fill="#657F91"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8876">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8876">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(676 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
