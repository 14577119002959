export function CardBgPink200({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_171_3163)">
        <rect width="280" height="164" rx="20" fill="#F8AEBC" />
        <g clipPath="url(#clip1_171_3163)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 113.889C79.7056 113.889 120 150.602 120 195.889C120 241.176 79.7056 277.889 30 277.889C-19.7056 277.889 -60 241.176 -60 195.889C-60 150.602 -19.7056 113.889 30 113.889ZM30 95.6666C90.7513 95.6666 140 140.538 140 195.889C140 251.24 90.7513 296.111 30 296.111C-30.7513 296.111 -80 251.24 -80 195.889C-80 140.538 -30.7513 95.6666 30 95.6666Z"
            fill="#FFD3DC"
            fillOpacity="0.996078"
          />
          <path
            d="M2.15202 51.5906C-2.90251 48.9318 -4.52184 42.8656 -1.46488 38.0414L0.940089 34.2462C3.99705 29.422 10.5727 27.6667 15.6272 30.3255L20.6629 32.9744C25.7175 35.6333 27.3368 41.6994 24.2798 46.5236L21.8749 50.3189C18.8179 55.143 12.2422 56.8984 7.18772 54.2395L2.15202 51.5906Z"
            fill="#EC7598"
          />
          <path
            d="M104.034 124.321C98.555 125.932 92.5675 122.873 90.6608 117.49L85.4524 102.784C83.5457 97.4006 86.4419 91.7305 91.9211 90.1196L109.617 84.9169C115.096 83.306 121.084 86.3642 122.99 91.7477L128.199 106.453C130.105 111.837 127.209 117.507 121.73 119.118L104.034 124.321Z"
            fill="#EC7598"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_171_3163">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_171_3163">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="matrix(1 0 0 -1 -120 195.889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
