import { ActivityAdvice } from "domain/entities/activities.entities";
import { HiLightBulb } from "react-icons/hi";
import { translate } from "ui/i18n";
import { CustomMarkdown } from "ui/pages/app/components/CustomMarkdown";

interface IProps {
  advice: ActivityAdvice;
}

export function Advice({ advice }: IProps) {
  return (
    <div className="space-y-3 rounded-[30px] bg-white p-4 py-6 md:p-6">
      <HiLightBulb size={46} color="#47548C" />
      <p className="text-xl font-bold text-secondaryGrey-900">
        {translate("ns1:Activities.Advice")}
      </p>
      <p className="text-sm font-semibold text-secondaryGrey-900">
        {advice.title}
      </p>
      <p className="text-sm font-medium text-secondaryGrey-700">
        <CustomMarkdown>{advice.content}</CustomMarkdown>
      </p>
    </div>
  );
}
