export function BannerLightGreen500({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8822)">
        <rect width="1080" height="210" fill="#53BBAD" />
        <g clipPath="url(#clip1_779_8822)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M704.924 -11.4273C701.2 -15.5051 694.874 -15.7912 690.797 -12.0665C682.267 -4.2753 674.438 7.44789 668.374 19.4381C662.274 31.4978 657.479 44.7814 655.559 56.2155C654.606 61.8873 654.257 67.698 655.176 72.9062C656.087 78.0668 658.552 84.0983 664.518 87.6228C670.451 91.1283 677.224 90.5981 683.205 88.6604C689.25 86.7017 695.99 82.8623 703.463 77.3708C728.918 58.664 752.598 56.9673 767.621 63.5025C781.998 69.7569 790.91 84.5274 786.168 105.865C781.21 128.175 779.081 145.365 779.883 158.298C780.684 171.212 784.581 181.917 793.804 188.137C802.537 194.027 813.456 194.022 823.51 192.547C833.832 191.032 845.797 187.534 858.261 183.795C875.797 178.534 888.115 182.402 895.022 188.431C902.052 194.568 904.632 203.756 901.745 212.418C899.998 217.658 902.83 223.321 908.069 225.067C913.309 226.814 918.972 223.982 920.718 218.743C926.24 202.179 921.079 184.631 908.175 173.365C895.148 161.992 875.34 157.79 852.514 164.638C839.751 168.467 829.222 171.494 820.607 172.759C811.723 174.062 807.259 173.088 804.987 171.555C803.204 170.353 800.473 167.181 799.845 157.06C799.218 146.958 800.859 131.953 805.692 110.203C812.325 80.3525 799.52 55.5686 775.599 45.1626C752.323 35.0373 721.121 39.574 691.619 61.2547C684.966 66.1442 680.201 68.6102 677.04 69.6341C676.141 69.9257 675.482 70.0681 675.021 70.1348C674.972 69.9419 674.921 69.7093 674.872 69.4311C674.503 67.3412 674.526 64.0362 675.283 59.5275C676.782 50.6006 680.759 39.2638 686.221 28.4648C691.718 17.5964 698.239 8.22285 704.285 2.70037C708.363 -1.02438 708.649 -7.34956 704.924 -11.4273ZM675.319 70.9553C675.32 70.9537 675.309 70.9334 675.284 70.8992C675.305 70.9398 675.318 70.9569 675.319 70.9553ZM674.187 70.181C674.154 70.1729 674.136 70.171 674.136 70.172C674.135 70.1731 674.152 70.1771 674.187 70.181Z"
            fill="#9BECE1"
          />
          <path
            d="M891.347 82.1221C896.782 83.876 902.61 80.8917 904.364 75.4565L910.125 57.6013C911.879 52.1661 908.895 46.3382 903.46 44.5843L885.605 38.8226C880.169 37.0687 874.341 40.053 872.588 45.4882L866.826 63.3434C865.072 68.7786 868.056 74.6065 873.491 76.3604L891.347 82.1221Z"
            fill="#68D8C9"
          />
          <path
            d="M854.708 110.692C859.654 107.836 861.348 101.512 858.493 96.5657L855.358 91.1371C852.503 86.191 846.178 84.4964 841.232 87.352L835.804 90.4862C830.858 93.3418 829.163 99.6663 832.019 104.612L835.153 110.041C838.008 114.987 844.333 116.682 849.279 113.826L854.708 110.692Z"
            fill="#68D8C9"
          />
          <path
            d="M656.16 129.306C661.106 126.45 662.801 120.126 659.945 115.18L656.811 109.751C653.955 104.805 647.631 103.11 642.685 105.966L637.256 109.1C632.31 111.956 630.616 118.28 633.471 123.226L636.605 128.655C639.461 133.601 645.786 135.296 650.732 132.44L656.16 129.306Z"
            fill="#68D8C9"
          />
          <path
            d="M709.345 215.521C714.78 217.275 720.608 214.291 722.362 208.855L729.671 186.206C731.425 180.77 728.44 174.943 723.005 173.189L700.355 165.88C694.92 164.126 689.092 167.11 687.338 172.545L680.029 195.195C678.275 200.63 681.26 206.458 686.695 208.212L709.345 215.521Z"
            fill="#68D8C9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8822">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8822">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(630 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
