export function BannerLightGreen500({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8888)">
        <rect width="1080" height="210" fill="#53BBAD" />
        <g clipPath="url(#clip1_779_8888)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M826 50C864.66 50 896 18.6599 896 -20C896 -58.6599 864.66 -90 826 -90C787.34 -90 756 -58.6599 756 -20C756 18.6599 787.34 50 826 50ZM826 70C875.706 70 916 29.7056 916 -20C916 -69.7056 875.706 -110 826 -110C776.294 -110 736 -69.7056 736 -20C736 29.7056 776.294 70 826 70Z"
            fill="#68D8C9"
          />
          <path
            d="M797.956 138.49C793.01 141.345 791.315 147.67 794.171 152.616L797.305 158.044C800.16 162.99 806.485 164.685 811.431 161.829L816.859 158.695C821.805 155.84 823.5 149.515 820.644 144.569L817.51 139.141C814.655 134.194 808.33 132.5 803.384 135.355L797.956 138.49Z"
            fill="#9BECE1"
          />
          <path
            d="M899.955 58.5248C894.519 56.7709 888.691 59.7552 886.938 65.1904L881.176 83.0456C879.422 88.4808 882.406 94.3087 887.841 96.0626L905.697 101.824C911.132 103.578 916.96 100.594 918.714 95.1587L924.475 77.3035C926.229 71.8683 923.245 66.0404 917.81 64.2865L899.955 58.5248Z"
            fill="#9BECE1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8888">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8888">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(676 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
