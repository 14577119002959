import { Technique } from "domain/entities/techniques.entities";
import { useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { CustomMarkdown } from "ui/pages/app/components/CustomMarkdown";
import { getDurationInHours } from "ui/utils/formatters";

interface IProps {
  technique: Technique;
  index: number;
  techniquesLength: number;
}

export function TechniqueComponent({
  technique,
  index,
  techniquesLength,
}: IProps) {
  const [isTechniqueOpen, setIsTechniqueOpen] = useState(false);

  return (
    <>
      {technique.id ? (
        <div className="mt-6 flex w-full flex-col space-y-4 rounded-[20px] bg-darkGrey-100 px-4 py-5 md:mt-8 ">
          <div className="flex w-full flex-row space-y-6">
            <div className="flex w-full flex-col md:flex-row">
              {techniquesLength > 1 && (
                <div className="h-full w-14">
                  <span className="flex h-14 w-14 items-center justify-center rounded-full bg-white text-2xl font-bold text-secondaryGrey-800">
                    {index + 1}
                  </span>
                </div>
              )}
              <div className="mx-0 !mt-4 flex w-full flex-col space-y-2 md:mx-4 md:!mt-0">
                <p className="text-base font-bold text-secondaryGrey-900">
                  {technique.title}
                </p>
                <span className="flex w-fit rounded-[36px] bg-white px-2 py-0.5 text-xs font-semibold text-secondaryGrey-800">
                  {getDurationInHours(Number(technique.duration))}
                </span>
                <p className="text-sm font-medium text-darkGrey-800">
                  {technique.objectif}
                </p>
              </div>
            </div>
            <div className="!mt-0 h-9 w-9">
              <button
                className="flex h-9 w-9 items-center justify-center rounded-xl bg-white"
                onClick={() => setIsTechniqueOpen(!isTechniqueOpen)}
              >
                <HiChevronDown
                  size={22}
                  className={`${
                    isTechniqueOpen ? "popover-open" : "popover-close"
                  }`}
                />
              </button>
            </div>
          </div>
          {isTechniqueOpen &&
            technique.sequences.map((sequence, index) => (
              <div
                className="flex w-full flex-col space-y-2 rounded-[20px] bg-white p-4"
                key={"technique-sequence" + index}
              >
                <li className="text-sm font-bold text-secondaryGrey-900">
                  {sequence.title}
                </li>
                <span className="flex w-fit rounded-[36px] bg-secondaryGrey-300 px-2 py-0.5 text-xs font-semibold text-secondaryGrey-800">
                  {getDurationInHours(Number(sequence.duration))}
                </span>
                <p className="text-sm font-medium text-darkGrey-700">
                  <CustomMarkdown>{sequence.storyline}</CustomMarkdown>
                </p>
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
}
