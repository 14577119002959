import { checkUserIsLogged, getUserInfos } from "domain/auth/auth.actions";
import { store } from "domain/store";

/**
 * Make technical actions to check if user is connected
 * - check local-storage : accessToken is present?
 * - if yes, make usersInfos api call to validate that the token is valid (not expired)
 * @returns user is connected or not
 */
export async function prepareAuth(
  {
    validateToken,
  }: {
    validateToken: boolean;
  } = { validateToken: true }
) {
  try {
    const result = await store.dispatch(checkUserIsLogged()).unwrap();
    if (!result.isLogged) {
      return { userIsConnected: false };
    }
    if (validateToken) {
      await store.dispatch(getUserInfos()).unwrap();
    }
    return { userIsConnected: true };
  } catch (error) {
    console.error(error);
  }
  return { userIsConnected: false };
}
