import {
  selectIsTechniquesLoading,
  selectTechniques,
} from "domain/techniques/techniques.selectors";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { TechniqueCard } from "ui/pages/app/components/activities/TechniqueCard";
import { DesktopActivitiesFilters } from "ui/pages/app/components/activities/DesktopActivitiesFilters";
import { PageHeader } from "ui/pages/app/components/PageHeader";
import { useWindowWidth } from "ui/utils/hooks";
import { HiAdjustments } from "react-icons/hi";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";
import { getTechniques } from "domain/techniques/techniques.actions";
import { MobileActivitiesFilters } from "ui/pages/app/components/activities/MobileActivitiesFilters";
import { useNavigate } from "react-router-dom";
import { getFiltersCount } from "ui/utils/get-filters-count";

export function Techniques() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsTechniquesLoading);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();
  const techniques = useAppSelector(selectTechniques);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<
    ActivitiesPageFilters & {
      [key: string]: string[] | string | boolean;
    }
  >({
    contexts: [],
    types: [],
    participants: "",
    modalities: [],
  });

  const minDuration = 0;
  const maxDuration = 145;

  const [duration, setDuration] = useState<DurationFilter>({
    $gte: minDuration,
    $lte: maxDuration,
  });

  const fetchTechniques = ({
    duration,
  }: {
    duration?: { $gte: number; $lte: number };
  }) => {
    dispatch(
      getTechniques({
        duration,
        pauses: false,
        search,
        isInniz: true,
        ...filters,
      })
    );
  };

  useEffect(() => {
    fetchTechniques({ duration });
  }, [filters, search]); //eslint-disable-line

  return (
    <div
      className={`flex flex-row overflow-x-hidden ${
        isFiltersOpen ? "filters-open" : "filters-closed"
      }`}
      style={{ width: isDesktop ? "100%" : "200vw" }}
    >
      <div className="w-screen px-2 pt-2 md:px-12 md:pt-8">
        <PageHeader
          search={search}
          setSearch={setSearch}
          title={translate("ns1:Routes.Techniques")}
          placeholder="TechniqueName"
        />

        {isDesktop ? (
          <DesktopActivitiesFilters
            filters={filters}
            setFilters={setFilters}
            fetchActivities={fetchTechniques}
            duration={duration}
            setDuration={setDuration}
            activitiesType="techniques"
            minDuration={minDuration}
            maxDuration={maxDuration}
          />
        ) : (
          <button
            className="mt-6 flex w-full items-center justify-center rounded-xl border border-darkGrey-200 bg-white py-2 text-base font-bold"
            onClick={() => setIsFiltersOpen(true)}
          >
            <HiAdjustments color="#2B3674" size="20" className="me-1.5" />
            {translate("ns1:Button.Filters")}
            <span className="ms-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs text-white">
              {getFiltersCount(filters, duration, minDuration, maxDuration)}
            </span>
          </button>
        )}

        <div className="mb-8 mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {!isLoading ? (
            <>
              {techniques.map((technique, index) => (
                <TechniqueCard
                  technique={technique}
                  key={"technique" + index}
                  onClick={() => navigate(`/technique/${technique?.id}`)}
                />
              ))}
            </>
          ) : (
            <>
              {[...Array(12)].map((_, index) => (
                <INSkeleton key={"techniques-skeleton" + index} />
              ))}
            </>
          )}
        </div>
      </div>
      {!isDesktop && (
        <MobileActivitiesFilters
          filters={filters}
          setFilters={setFilters}
          fetchActivities={fetchTechniques}
          duration={duration}
          setDuration={setDuration}
          activitiesType="techniques"
          minDuration={minDuration}
          maxDuration={maxDuration}
          setIsFiltersOpen={setIsFiltersOpen}
        />
      )}
    </div>
  );
}
