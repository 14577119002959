export function BannerPink300({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8906)">
        <rect width="1080" height="210" fill="#EC7598" />
        <g clipPath="url(#clip1_779_8906)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M826 80C881.228 80 926 35.2285 926 -20C926 -75.2285 881.228 -120 826 -120C770.772 -120 726 -75.2285 726 -20C726 35.2285 770.772 80 826 80ZM826 100C892.274 100 946 46.2742 946 -20C946 -86.2742 892.274 -140 826 -140C759.726 -140 706 -86.2742 706 -20C706 46.2742 759.726 100 826 100Z"
            fill="#F8AEBC"
          />
          <path
            d="M797.956 138.49C793.01 141.345 791.315 147.67 794.171 152.616L797.305 158.044C800.16 162.99 806.485 164.685 811.431 161.829L816.859 158.695C821.805 155.84 823.5 149.515 820.644 144.569L817.51 139.141C814.655 134.195 808.33 132.5 803.384 135.356L797.956 138.49Z"
            fill="#E14277"
          />
          <path
            d="M899.955 58.5248C894.519 56.7709 888.691 59.7552 886.938 65.1904L881.176 83.0456C879.422 88.4808 882.406 94.3087 887.841 96.0626L905.697 101.824C911.132 103.578 916.96 100.594 918.714 95.1587L924.475 77.3035C926.229 71.8683 923.245 66.0404 917.81 64.2865L899.955 58.5248Z"
            fill="#E14277"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8906">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8906">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(676 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
