import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";
import { translate } from "ui/i18n";
import { Snackbar } from "ui/pages/snackbar";
import { removeSessionInLocalStorage } from "./auth/auth-session-storage";

const instance = axios.create();

instance.interceptors.response.use(null, onRequestError);

type RequestOptions = {
  params?: unknown;
  api?: "core" | "connect";
  includeAuthorization?: boolean;
  additionalHeaders?: Record<string, string>;
};

const defaultRequestOptions: RequestOptions = {
  params: {},
  api: "core",
  includeAuthorization: true,
  additionalHeaders: {},
};

export class HttpService {
  private static accessToken: string = "";

  static setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  static async get(
    url: string,
    options: RequestOptions = defaultRequestOptions
  ) {
    const response = this.request(
      options.api,
      url,
      "GET",
      null,
      options.params,
      options.includeAuthorization,
      options.additionalHeaders
    );
    return response;
  }

  static async post(
    url: string,
    data: unknown,
    options: RequestOptions = defaultRequestOptions
  ) {
    const response = this.request(
      options.api,
      url,
      "POST",
      data,
      options.params,
      options.includeAuthorization,
      options.additionalHeaders
    );
    return response;
  }

  static async patch(
    url: string,
    data: unknown,
    options: RequestOptions = defaultRequestOptions
  ) {
    const response = this.request(
      options.api,
      url,
      "PATCH",
      data,
      options.params,
      options.includeAuthorization,
      options.additionalHeaders
    );
    return response;
  }

  static async delete(
    url: string,
    options: RequestOptions = defaultRequestOptions
  ) {
    const response = this.request(
      options.api,
      url,
      "DELETE",
      null,
      options.params,
      options.includeAuthorization,
      options.additionalHeaders
    );
    return response;
  }

  private static async request(
    api: "core" | "connect",
    url: string,
    method: Method,
    data?: unknown,
    params?: unknown,
    includeAuthorization = true,
    additionalHeaders?: Record<string, string>
  ) {
    const config: AxiosRequestConfig = {};
    config.url = getUrl(api, url);
    config.method = method;
    config.data = data;
    config.params = params;
    config.headers = {};

    if (includeAuthorization) {
      config.headers = { Authorization: `Bearer ${this.accessToken}` };
    }

    if (additionalHeaders) {
      config.headers = { ...config.headers, ...additionalHeaders };
    }

    const response = await instance.request(config);
    return response;
  }
}

function onRequestError(error: AxiosError) {
  console.log(error);
  switch (error.response?.status) {
    case 401:
      if (
        (error.response.data as { data: { name: string } }).data.name ===
        "TokenExpiredError"
      )
        Snackbar.showError({
          title: translate("ns1:Errors.SessionTimedOut"),
        });
      removeSessionInLocalStorage();
      window.location.href = "/auth";
      break;
  }
  return Promise.reject(error);
}

function getUrl(api: "core" | "connect", url: string) {
  switch (api) {
    case "core":
      return `${process.env.REACT_APP_CORE_URL}/${url}`;
    case "connect":
      return `${process.env.REACT_APP_CONNECT_URL}/${url}`;
    default:
      return `${process.env.REACT_APP_CORE_URL}/${url}`;
  }
}
