/* eslint-disable */
import { Link } from "react-router-dom";
import { sidebarRoutes } from "./SidebarRoutes";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { selectUserName } from "domain/auth/auth.selectors";
import { translate } from "ui/i18n";
import { LogoInnizio } from "ui/assets/svg/LogoInnizio";
import { HiChat, HiLogout } from "react-icons/hi";
import { useWindowWidth } from "ui/utils/hooks";
import { useContext } from "react";
import { SidebarContext } from "ui/pages/layouts/components/SidebarContext";
import { logoutUser } from "domain/auth/auth.actions";

export function Sidebar() {
  const activeRoute = (routeName: string) => {
    return location.pathname === routeName;
  };
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
  const isDesktop = useWindowWidth();
  const userName = useAppSelector(selectUserName);
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await dispatch(logoutUser());
  };

  const openReviewUrl = () => {
    window.open("https://innizio.fr/donnez-votre-avis", "_blank").focus();
  };

  return (
    <div className="!z-50 flex h-dvh min-h-full w-[80vw] flex-col justify-between bg-white shadow-2xl shadow-white/5 md:fixed md:h-full md:w-56">
      <div>
        <div className="flex flex-row items-center px-6 py-7">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-secondaryGrey-800 text-2xl font-medium text-white ">
            {userName?.charAt(0).toUpperCase()}
          </div>

          <div className="ms-3 flex flex-col">
            <span className="text-sm font-medium text-darkGrey-800">
              {translate("ns1:Sidebar.WelcomeBack")}
            </span>
            <span className="mt-0.5 text-base font-bold text-darkGrey-800">
              {userName}
            </span>
          </div>
        </div>
        <div className="mb-7 h-px bg-darkGrey-200" />

        <ul className="mb-auto">
          {sidebarRoutes.map((route, index) => (
            <div
              className="relative mb-3 flex hover:cursor-pointer"
              key={"main-path" + index}
            >
              <li className="flex w-full cursor-pointer items-center px-6">
                <Link
                  key={index}
                  to={route.path}
                  className={`
                            flex items-center py-[10px] ${
                              activeRoute(route.path) === true
                                ? "font-bold text-darkGrey-900"
                                : "font-medium text-darkGrey-800"
                            }`}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <route.icon
                    className={
                      activeRoute(route.path) === true
                        ? "text-pink-400"
                        : "text-darkGrey-800"
                    }
                    size={25}
                  />{" "}
                  <p
                    className={`ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-darkGrey-900"
                        : "font-medium text-darkGrey-800"
                    }`}
                  >
                    {route.name}
                  </p>
                </Link>
              </li>
            </div>
          ))}
        </ul>
      </div>

      <div>
        <div className="mb-7 h-px bg-darkGrey-200" />
        <ul>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="flex w-full cursor-pointer items-center px-6">
              <button
                className="flex items-center py-[10px] font-medium text-darkGrey-800"
                onClick={openReviewUrl}
              >
                <HiChat className="text-darkGrey-800" size={25} />{" "}
                <p className="ml-4 flex font-medium text-darkGrey-800">
                  {translate("ns1:Sidebar.LeaveReview")}
                </p>
              </button>
            </li>
          </div>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="flex w-full cursor-pointer items-center px-6">
              <button
                className="flex items-center py-[10px] font-medium text-darkGrey-800"
                onClick={handleLogout}
              >
                <HiLogout className="text-darkGrey-800" size={25} />{" "}
                <p className="ml-4 flex font-medium text-darkGrey-800">
                  {translate("ns1:Sidebar.Logout")}
                </p>
              </button>
            </li>
          </div>
        </ul>
        <div className="flex justify-center px-6 pb-5 pt-7">
          <LogoInnizio width={80} />
        </div>
        {!isDesktop && isSidebarOpen && (
          <div
            className="absolute left-[80vw] top-0 z-20 min-h-dvh w-[20vw] cursor-pointer"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}
      </div>
    </div>
  );
}
