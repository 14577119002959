export function CardBgPaleGreen200({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_76_4874)">
        <rect width="280" height="164" rx="20" fill="#B5DDD6" />
        <g clipPath="url(#clip1_76_4874)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 141.222C63.1371 141.222 90 165.697 90 195.888C90 226.08 63.1371 250.555 30 250.555C-3.13708 250.555 -30 226.08 -30 195.888C-30 165.697 -3.13708 141.222 30 141.222ZM30 123C74.1828 123 110 155.633 110 195.888C110 236.144 74.1828 268.777 30 268.777C-14.1828 268.777 -50 236.144 -50 195.888C-50 155.633 -14.1828 123 30 123Z"
            fill="#CEFFF8"
          />
          <path
            d="M2.15202 51.5901C-2.90251 48.9313 -4.52184 42.8651 -1.46488 38.041L0.940089 34.2457C3.99705 29.4215 10.5727 27.6662 15.6272 30.325L20.6629 32.9739C25.7175 35.6328 27.3368 41.6989 24.2798 46.5231L21.8749 50.3184C18.8179 55.1425 12.2422 56.8979 7.18772 54.2391L2.15202 51.5901Z"
            fill="#B5F6EC"
          />
          <path
            d="M104.034 124.32C98.555 125.931 92.5675 122.873 90.6608 117.49L85.4524 102.784C83.5457 97.4004 86.4419 91.7303 91.9211 90.1193L109.617 84.9167C115.096 83.3057 121.084 86.364 122.99 91.7475L128.199 106.453C130.105 111.837 127.209 117.507 121.73 119.118L104.034 124.32Z"
            fill="#B5F6EC"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_76_4874">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_76_4874">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="matrix(1 0 0 -1 -120 195.889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
