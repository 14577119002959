export function CardBgPink500({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_170_3018)">
        <rect width="280" height="164" rx="20" fill="#C5205D" />
        <g clipPath="url(#clip1_170_3018)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 86.5556C96.2742 86.5556 150 135.506 150 195.889C150 256.272 96.2742 305.222 30 305.222C-36.2742 305.222 -90 256.272 -90 195.889C-90 135.506 -36.2742 86.5556 30 86.5556ZM30 68.3334C107.32 68.3334 170 125.442 170 195.889C170 266.336 107.32 323.444 30 323.444C-47.3199 323.444 -110 266.336 -110 195.889C-110 125.442 -47.3199 68.3334 30 68.3334Z"
            fill="#EC7598"
          />
          <path
            d="M2.15202 51.5906C-2.90251 48.9318 -4.52184 42.8656 -1.46488 38.0414L0.940089 34.2462C3.99705 29.422 10.5727 27.6667 15.6272 30.3255L20.6629 32.9744C25.7175 35.6333 27.3368 41.6994 24.2798 46.5236L21.8749 50.3189C18.8179 55.143 12.2422 56.8984 7.18772 54.2395L2.15202 51.5906Z"
            fill="#E14277"
          />
          <path
            d="M104.034 124.321C98.555 125.932 92.5675 122.873 90.6608 117.49L85.4524 102.784C83.5457 97.4006 86.4419 91.7305 91.9211 90.1196L109.617 84.9169C115.096 83.306 121.084 86.3642 122.99 91.7477L128.199 106.453C130.105 111.837 127.209 117.507 121.73 119.118L104.034 124.321Z"
            fill="#E14277"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_170_3018">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_170_3018">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="matrix(1 0 0 -1 -120 195.889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
