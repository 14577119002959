export function BannerPink500({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8834)">
        <rect width="1080" height="210" fill="#C5205D" />
        <g clipPath="url(#clip1_779_8834)">
          <path
            d="M711.841 134.111C716.787 136.966 718.481 143.291 715.626 148.237L712.492 153.665C709.636 158.611 703.312 160.306 698.366 157.45L692.937 154.316C687.991 151.461 686.296 145.136 689.152 140.19L692.286 134.762C695.142 129.816 701.466 128.121 706.412 130.977L711.841 134.111Z"
            fill="#E14277"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M906.695 227C912.218 227 916.695 222.523 916.695 217C916.695 172.129 894.576 137.454 868.79 116.969C863.451 112.726 857.897 109.046 852.283 105.991C852.951 101.156 853.099 95.9045 852.666 90.239C851.041 68.9546 836.071 54.4679 818.193 44.3012C800.25 34.0979 777.158 26.9227 754.796 20.9476C746.504 18.7318 738.37 16.6929 730.528 14.7269C716.637 11.2448 703.659 7.99132 692.324 4.38889C683.57 1.6065 676.496 -1.18303 671.326 -4.10041C665.955 -7.13102 663.979 -9.55782 663.387 -10.939C661.211 -16.0153 655.332 -18.3668 650.256 -16.1913C645.18 -14.0157 642.828 -8.13695 645.004 -3.06066C648.175 4.33884 654.755 9.51319 661.497 13.3177C668.44 17.2355 677.034 20.5151 686.266 23.4494C698.138 27.2223 712.232 30.76 726.593 34.3645C734.297 36.2981 742.078 38.251 749.633 40.2698C771.89 46.2169 792.788 52.8624 808.306 61.6868C823.888 70.5478 831.849 80.2958 832.724 91.7613C832.894 93.9918 832.946 96.0737 832.895 98.0166C831.466 97.621 830.044 97.2716 828.633 96.9694C815.688 94.1966 801.966 95.1252 791.923 103.211C786.831 107.311 782.872 112.072 781.087 117.556C779.172 123.437 780.067 129.291 783.222 134.082C788.895 142.698 800.187 145.912 809.766 145.781C820.032 145.64 831.685 141.784 840.413 132.402C842.407 130.259 844.195 127.886 845.752 125.285C849.284 127.392 852.84 129.84 856.35 132.628C878.064 149.879 896.695 179.074 896.695 217C896.695 222.523 901.172 227 906.695 227ZM827.123 117.173C826.682 117.751 826.229 118.285 825.771 118.779C821.336 123.545 815.206 125.704 809.492 125.783C804.083 125.857 801.184 124.181 800.247 123.404C800.608 122.657 801.659 121.05 804.467 118.789C807.924 116.005 814.514 114.399 824.444 116.526C825.324 116.714 826.218 116.93 827.123 117.173Z"
            fill="#EC7598"
          />
          <path
            d="M730.391 22.6757C735.826 20.9218 741.654 23.9061 743.408 29.3413L749.17 47.1965C750.924 52.6317 747.939 58.4596 742.504 60.2135L724.649 65.9752C719.214 67.7291 713.386 64.7448 711.632 59.3096L705.87 41.4544C704.116 36.0192 707.101 30.1913 712.536 28.4374L730.391 22.6757Z"
            fill="#E14277"
          />
          <path
            d="M824.841 181.111C829.787 183.966 831.481 190.291 828.626 195.237L825.492 200.665C822.636 205.611 816.312 207.306 811.366 204.45L805.937 201.316C800.991 198.461 799.296 192.136 802.152 187.19L805.286 181.762C808.142 176.816 814.466 175.121 819.412 177.977L824.841 181.111Z"
            fill="#E14277"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8834">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8834">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(630 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
