import { z } from "zod";

export const seanceDtoSchema = z.object({
  id: z.number(),
  innizRef: z.string().nullable(),
  title: z.string(),
  objectif: z.string().nullable(),
  premium: z.boolean().nullable(),
  duration: z.string().nullable(),
  level: z.string().nullable(),
  participants: z.number().nullable(),
  remote: z.boolean(),
  onsite: z.boolean(),
  active: z.boolean(),
  validated: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  techniques: z.any().optional(), //techniquesDtoSchema.optional(),
  userId: z.number().nullable(),
  note: z.string().nullable(),
});

export const seancesDtoSchema = z.array(seanceDtoSchema);
