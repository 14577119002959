import { HTMLInputTypeAttribute } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

interface Props {
  label?: string;
  error?: FieldError;
  register?: UseFormRegisterReturn;
  height?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  className?: string;
  value?: string | number;
  rows?: number;
}

export function INTextarea({
  label,
  error,
  register,
  disabled,
  placeholder,
  className,
  value,
  rows = 4,
}: Props) {
  return (
    <div className={`flex flex-col ${className}`}>
      <label className={`w-full`}>
        <span className="text-sm font-bold text-darkGrey-700">{label}</span>
        <div className="relative mt-2">
          <textarea
            disabled={disabled}
            rows={rows}
            value={value}
            placeholder={placeholder}
            className={`textarea-scroll flex w-full items-center justify-center rounded-xl border p-3 text-sm font-medium outline-none disabled:border-none disabled:bg-secondaryGrey-400 ${
              error
                ? "border-error text-error"
                : "border-darkGrey-500 text-darkGrey-700"
            }`}
            {...register}
          />
        </div>
      </label>

      {error && error.message && (
        <p className="mt-1 text-sm font-medium text-error">{error.message}</p>
      )}
    </div>
  );
}
