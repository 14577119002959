export function BannerLightGreen400({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8864)">
        <rect width="1080" height="210" fill="#68D8C9" />
        <g clipPath="url(#clip1_779_8864)">
          <path
            d="M860.444 43.6828C864.351 44.0782 867.839 41.2312 868.234 37.324L868.332 36.3592C869.464 25.1703 861.312 15.1822 850.123 14.05C838.934 12.9179 828.946 21.0705 827.813 32.2594L827.716 33.2242C827.32 37.1314 830.167 40.6194 834.075 41.0147L860.444 43.6828Z"
            fill="#53BBAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M843.96 144.262C849.796 154.214 850.04 173.289 820.859 214.192C817.652 218.688 818.697 224.933 823.192 228.141C827.688 231.348 833.933 230.304 837.141 225.808C866.46 184.711 873.829 155.661 861.212 134.145C855.197 123.887 845.427 117.009 835.301 111.858C825.744 106.997 814.782 103.156 804.549 99.5705C803.947 99.3592 803.346 99.1489 802.749 98.9393C791.677 95.0538 781.518 91.3866 773.058 86.776C764.634 82.1854 758.915 77.1881 755.813 71.2368C749.924 59.9398 750.683 38.8195 780.706 -4.28439C783.862 -8.81626 782.748 -15.049 778.216 -18.2056C773.684 -21.3622 767.451 -20.2473 764.295 -15.7154C734.068 27.6807 726.389 58.0604 738.078 80.4822C743.765 91.3903 753.339 98.807 763.487 104.338C773.599 109.848 785.292 114.009 796.126 117.811C796.574 117.968 797.02 118.125 797.465 118.281C808.116 122.016 817.909 125.45 826.233 129.685C834.898 134.092 840.748 138.785 843.96 144.262Z"
            fill="#B5F6EC"
          />
          <path
            d="M724.136 84.6271C721.299 84.8172 719.154 87.2707 719.344 90.1071L719.391 90.8075C719.935 98.93 726.961 105.073 735.084 104.529C743.206 103.985 749.35 96.9588 748.805 88.8363L748.758 88.1359C748.568 85.2995 746.115 83.1542 743.278 83.3443L724.136 84.6271Z"
            fill="#53BBAD"
          />
          <path
            d="M772.295 194.346C773.03 198.204 776.754 200.735 780.611 200L781.564 199.818C792.611 197.712 799.859 187.05 797.753 176.003C795.647 164.955 784.985 157.707 773.938 159.813L772.985 159.995C769.127 160.73 766.596 164.454 767.332 168.311L772.295 194.346Z"
            fill="#53BBAD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8864">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8864">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(630 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
