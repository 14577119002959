import { Radio } from "@chakra-ui/react";

interface IProps {
  value: string;
  text: string;
  selectedValue: string;
}

export function INRadioButton({ value, text, selectedValue }: IProps) {
  return (
    <Radio
      value={value}
      colorScheme="pink"
      style={{
        border: selectedValue === value ? "none" : "1px solid #D5DAE1",
        width: "20px",
        height: "20px",
      }}
    >
      <span
        className={`w-fit cursor-pointer text-base ${
          selectedValue === value
            ? "text-darkGrey-900"
            : "text-secondaryGrey-700"
        } ${selectedValue === value ? "font-bold" : ""}`}
      >
        {text}
      </span>
    </Radio>
  );
}
