import {
  getSeance,
  getSeanceAdvice,
  getSeances,
  removeSeanceAdvice,
} from "domain/seances/seances.actions";
import { store } from "domain/store";
import {
  getHomeTechniques,
  getTechnique,
  getTechniqueAdvice,
} from "domain/techniques/techniques.actions";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { prepareAuth } from "ui/navigation/loaders/prepareAuth";

export async function homeLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(
    getHomeTechniques({
      context: "INTRO",
      $limit: 10,
      "$sort[createdAt]": -1,
      isInniz: true,
    })
  );
  store.dispatch(
    getHomeTechniques({
      context: "CORE",
      $limit: 10,
      "$sort[createdAt]": -1,
      isInniz: true,
    })
  );
  store.dispatch(
    getHomeTechniques({
      context: "CONCLUSION",
      $limit: 10,
      "$sort[createdAt]": -1,
      isInniz: true,
    })
  );

  return null;
}

export async function techniquesLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  // store.dispatch(getTechniques({}));

  return null;
}

export async function seancesLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  // store.dispatch(getSeances({}));

  return null;
}

export async function techniqueLoader({ params }: LoaderFunctionArgs) {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(getTechnique(Number(params.id)));
  store.dispatch(getTechniqueAdvice(Number(params.id)));

  return null;
}

export async function seanceLoader({ params }: LoaderFunctionArgs) {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  const { seance } = await store
    .dispatch(getSeance(Number(params.id)))
    .unwrap();

  //if the seance is not created by the user, get advice, else remove advice
  if (!seance.userId) {
    store.dispatch(getSeanceAdvice(Number(params.id)));
  } else store.dispatch(removeSeanceAdvice());

  return null;
}

export async function createSeanceLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  return null;
}

export async function myCreationsLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(getSeances({ isInniz: false, participants: "" }));
  return null;
}
