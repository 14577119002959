import { createBrowserRouter } from "react-router-dom";

import { authLoader } from "ui/navigation/loaders/protectedLoader";
import { Error } from "ui/pages/error/Error";
import { appRoutes } from "ui/navigation/routes/app/appRoutes";
import { authRoutes } from "ui/navigation/routes/auth/authRoutes";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error />,
    children: [
      {
        loader: authLoader,
        children: authRoutes,
      },
      ...appRoutes,
    ],
  },
]);
