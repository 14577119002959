import { HTMLInputTypeAttribute, useState } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { HiEye, HiEyeOff } from "react-icons/hi";

interface Props {
  label?: string;
  error?: FieldError;
  register?: UseFormRegisterReturn;
  height?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  className?: string;
  value?: string | number;
}

export function INInput({
  label,
  error,
  register,
  height = "46px",
  disabled,
  type = "text",
  placeholder,
  className,
  value,
}: Props) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const getType = () => {
    if (type === "password") {
      return passwordVisible ? "text" : "password";
    }
    return type;
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <label className={`w-full`}>
        <span className="text-sm font-bold text-darkGrey-700">{label}</span>
        <div className="relative mt-2">
          <input
            disabled={disabled}
            type={getType()}
            value={value}
            placeholder={placeholder}
            className={`flex w-full items-center justify-center rounded-xl border p-3 text-sm font-medium outline-none disabled:border-none disabled:bg-secondaryGrey-400 ${
              error
                ? "border-error text-error"
                : "border-darkGrey-500 text-darkGrey-700"
            } ${type === "password" ? "pr-12" : ""}`}
            {...register}
            style={{
              height,
            }}
          />
          {type === "password" && (
            <div
              className="absolute right-0 top-0 flex  h-full w-12 cursor-pointer items-center justify-center text-darkGrey-700"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible && <HiEyeOff size={16} />}
              {!passwordVisible && <HiEye size={16} />}
            </div>
          )}
        </div>
      </label>

      {error && error.message && (
        <p className="mt-1 text-sm font-medium text-error">{error.message}</p>
      )}
    </div>
  );
}
