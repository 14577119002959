import { Sequence } from "domain/entities/techniques.entities";
import { getDurationInHours } from "ui/utils/formatters";
import { CustomMarkdown } from "ui/pages/app/components/CustomMarkdown";

interface IProps {
  sequence: Sequence;
  index: number;
  sequencesLength: number;
}

export function SequenceComponent({
  sequence,
  index,
  sequencesLength,
}: IProps) {
  return (
    <div className="mt-6 flex flex-col space-y-6 rounded-[20px] bg-darkGrey-100 px-4 py-5 md:mt-8 md:flex-row">
      {sequencesLength > 1 && (
        <div className="h-full w-14">
          <span className="flex h-14 w-14 items-center justify-center rounded-full bg-white text-2xl font-bold text-secondaryGrey-800">
            {index + 1}
          </span>
        </div>
      )}
      <div
        className={`flex flex-col space-y-2 md:mx-4 md:!mt-0 ${
          sequencesLength > 1 ? "!mt-4" : ""
        }`}
      >
        <p className="text-base font-bold text-secondaryGrey-900">
          {sequence.title}
        </p>
        <span className="flex w-fit rounded-[36px] bg-white px-2 py-0.5 text-xs font-semibold text-secondaryGrey-800">
          {getDurationInHours(Number(sequence.duration))}
        </span>
        <p className="text-sm font-medium text-darkGrey-800">
          <CustomMarkdown>{sequence.storyline}</CustomMarkdown>
        </p>
      </div>
    </div>
  );
}
