// importing the required chakra libraries
import { extendTheme } from "@chakra-ui/react";

export const customChakraTheme = extendTheme({
  fonts: {
    heading: "Plus Jakarta Sans, sans-serif",
    body: "Plus Jakarta Sans, sans-serif",
  },
  styles: {
    global: {
      heading: {
        color: "#2B3674",
      },
      body: {
        color: "#2B3674",
      },
    },
  },
});
