// import { Dev } from "@/ui/pages/root/dev/Dev";
// import { Login } from "@/ui/pages/root/login/Login";

import { RouteObject } from "react-router-dom";

import { Register } from "ui/pages/auth/register/Register";
import { AuthLayout } from "ui/pages/layouts/AuthLayout";
import { Auth } from "ui/pages/auth/Auth";
import { Login } from "ui/pages/auth/Login";
import { TellUsMore } from "ui/pages/auth/register/TellUsMore";

export const authRoutes: RouteObject[] = [
  {
    path: "auth",
    element: (
      <AuthLayout>
        <Auth />
      </AuthLayout>
    ),
  },
  {
    path: "login",
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: "register",
    element: (
      <AuthLayout>
        <Register />
      </AuthLayout>
    ),
  },
  {
    path: "tell-us-more",
    element: (
      <AuthLayout>
        <TellUsMore />
      </AuthLayout>
    ),
  },
];
