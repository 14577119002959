export function LogoInnizio({
  color = "#47548C",
  height = 32,
  width = 120,
}: {
  color?: string;
  height?: number;
  width?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1407_53320)">
        <path
          d="M89.3999 9.2246C88.6668 8.55591 88.2969 7.71841 88.2969 6.71199C88.2969 5.70562 88.6668 4.82757 89.3999 4.14539C90.1329 3.46996 91.108 3.12549 92.3184 3.12549C93.5289 3.12549 94.4705 3.46321 95.2035 4.14539C95.9364 4.82757 96.3063 5.6786 96.3063 6.71199C96.3063 7.74548 95.9364 8.55591 95.2035 9.2246C94.4705 9.89329 93.5088 10.2242 92.3184 10.2242C91.1281 10.2242 90.1329 9.89329 89.3999 9.2246ZM95.7146 13.4054V30.4058C95.7146 31.1488 95.1094 31.7566 94.3696 31.7566H90.2068C89.4672 31.7566 88.8618 31.1488 88.8618 30.4058V13.4054C88.8618 12.6626 89.4672 12.0545 90.2068 12.0545H94.3696C95.1094 12.0545 95.7146 12.6626 95.7146 13.4054Z"
          fill={color}
        />
        <path
          d="M117.725 16.5464C116.83 15.0265 115.606 13.8514 114.053 13.0341C112.5 12.2167 110.751 11.8048 108.787 11.8048C106.824 11.8048 105.075 12.2167 103.522 13.0341C101.968 13.8514 100.744 15.0265 99.8499 16.5464C98.9555 18.066 98.5049 19.8491 98.5049 21.8821C98.5049 23.9152 98.9488 25.732 99.8364 27.2585C100.724 28.7781 101.941 29.9534 103.488 30.7708C105.035 31.5879 106.79 32 108.754 32C110.717 32 112.473 31.5879 114.033 30.7708C115.593 29.9534 116.824 28.7714 117.725 27.2382C118.619 25.7051 119.07 23.922 119.07 21.8821C119.07 19.8424 118.619 18.066 117.725 16.5464ZM114.631 22.4496L113.186 23.5302C112.123 24.3272 111.181 25.2728 110.395 26.3467L109.386 27.7179C109.117 28.0826 108.579 28.0826 108.31 27.7179L107.268 26.3129C106.487 25.2592 105.559 24.3272 104.517 23.5436L103.064 22.4563C102.701 22.1861 102.708 21.6457 103.064 21.3755L104.457 20.3421C105.519 19.5518 106.46 18.6064 107.247 17.5392L108.303 16.1073C108.572 15.7425 109.11 15.7425 109.379 16.1073L110.455 17.5594C111.242 18.6198 112.177 19.5587 113.239 20.349L114.625 21.3755C114.988 21.6457 114.988 22.1861 114.625 22.4563L114.631 22.4496Z"
          fill={color}
        />
        <path
          d="M8.465 13.4054V30.4058C8.465 31.1488 7.85976 31.7568 7.12002 31.7568H2.9573C2.21756 31.7568 1.6123 31.1488 1.6123 30.4058V13.4054C1.6123 12.6626 2.21756 12.0546 2.9573 12.0546H7.12002C7.85976 12.0546 8.465 12.6626 8.465 13.4054Z"
          fill={color}
        />
        <path
          d="M30.5968 14.1417C31.9284 15.6546 32.5874 17.6943 32.5874 20.2677V30.3991C32.5874 31.1421 31.9821 31.7499 31.2423 31.7499H27.0797C26.3399 31.7499 25.7347 31.1421 25.7347 30.3991V21.1795C25.7347 20.0583 25.4455 19.1736 24.8605 18.5251C24.2754 17.8835 23.4954 17.5593 22.5201 17.5593C21.545 17.5593 20.6909 17.8835 20.1059 18.5251C19.5208 19.1669 19.2317 20.0516 19.2317 21.1795V30.3991C19.2317 31.1421 18.6265 31.7499 17.8867 31.7499H13.7239C12.9843 31.7499 12.3789 31.1421 12.3789 30.3991V13.3987C12.3789 12.6557 12.9843 12.0478 13.7239 12.0478H17.8867C18.6265 12.0478 19.2317 12.6557 19.2317 13.3987V14.8576C19.8369 13.966 20.6507 13.25 21.6796 12.6962C22.7017 12.1492 23.8854 11.8723 25.21 11.8723C27.4697 11.8723 29.2653 12.6288 30.5968 14.135V14.1417Z"
          fill={color}
        />
        <path
          d="M54.5908 14.1417C55.9223 15.6546 56.5814 17.6943 56.5814 20.2677V30.3991C56.5814 31.1421 55.9762 31.7499 55.2364 31.7499H51.0738C50.334 31.7499 49.7288 31.1421 49.7288 30.3991V21.1795C49.7288 20.0583 49.4397 19.1736 48.8544 18.5251C48.2694 17.8835 47.4893 17.5593 46.5142 17.5593C45.5392 17.5593 44.6851 17.8835 44.1 18.5251C43.515 19.1669 43.2258 20.0516 43.2258 21.1795V30.3991C43.2258 31.1421 42.6204 31.7499 41.8808 31.7499H37.718C36.9782 31.7499 36.373 31.1421 36.373 30.3991V13.3987C36.373 12.6557 36.9782 12.0478 37.718 12.0478H41.8808C42.6204 12.0478 43.2258 12.6557 43.2258 13.3987V14.8576C43.831 13.966 44.6446 13.25 45.6736 12.6962C46.6958 12.1492 47.8793 11.8723 49.2042 11.8723C51.4638 11.8723 53.2592 12.6288 54.5908 14.135V14.1417Z"
          fill={color}
        />
        <path
          d="M60.8997 9.2246C60.1666 8.55591 59.7969 7.71841 59.7969 6.71199C59.7969 5.70562 60.1666 4.82757 60.8997 4.14539C61.6327 3.46996 62.6078 3.12549 63.8184 3.12549C65.0287 3.12549 65.9703 3.46321 66.7033 4.14539C67.4362 4.82757 67.8061 5.6786 67.8061 6.71199C67.8061 7.74548 67.4362 8.55591 66.7033 9.2246C65.9703 9.89329 65.0086 10.2242 63.8184 10.2242C62.628 10.2242 61.6327 9.89329 60.8997 9.2246ZM67.2144 13.4054V30.4058C67.2144 31.1488 66.6092 31.7566 65.8694 31.7566H61.7068C60.967 31.7566 60.3618 31.1488 60.3618 30.4058V13.4054C60.3618 12.6626 60.967 12.0545 61.7068 12.0545H65.8694C66.6092 12.0545 67.2144 12.6626 67.2144 13.4054Z"
          fill={color}
        />
        <path
          d="M77.6312 26.0291H84.6452C85.385 26.0291 85.9902 26.637 85.9902 27.38V30.4058C85.9902 31.1488 85.385 31.7568 84.6452 31.7568H71.4241C70.6845 31.7568 70.0791 31.1488 70.0791 30.4058V26.8396C70.0791 26.502 70.207 26.1778 70.4356 25.9278L77.8801 17.7822H71.4981C70.7583 17.7822 70.1531 17.1744 70.1531 16.4314V13.4054C70.1531 12.6626 70.7583 12.0546 71.4981 12.0546H84.3695C85.1093 12.0546 85.7147 12.6626 85.7147 13.4054V16.9582C85.7147 17.3027 85.58 17.6403 85.3448 17.8904L77.6379 26.0359L77.6312 26.0291Z"
          fill={color}
        />
        <path
          d="M4.59144 0.234701L3.71048 1.43021C3.05144 2.32177 2.27135 3.10526 1.38366 3.76718L0.226971 4.62496C-0.0756571 4.84784 -0.0756571 5.30039 0.226971 5.52328L1.43745 6.42834C2.3117 7.08357 3.08507 7.86019 3.73065 8.73822L4.59817 9.90679C4.82009 10.2108 5.27066 10.2108 5.49931 9.90679L6.33993 8.76529C6.99895 7.87382 7.77905 7.08357 8.66675 6.4216L9.87051 5.52328C10.1731 5.30039 10.1731 4.84784 9.87051 4.61822L8.72054 3.76042C7.83957 3.10526 7.05949 2.32177 6.40045 1.43697L5.50604 0.22796C5.2841 -0.0759866 4.83354 -0.0759866 4.61162 0.22796L4.59144 0.234701Z"
          fill={color}
        />
        <path
          d="M65.8689 12.0546H61.7063C60.9633 12.0546 60.3613 12.6594 60.3613 13.4054V30.4058C60.3613 31.152 60.9633 31.7568 61.7063 31.7568H65.8689C66.6117 31.7568 67.2139 31.152 67.2139 30.4058V13.4054C67.2139 12.6594 66.6117 12.0546 65.8689 12.0546Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1407_53320">
          <rect width="119.07" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
