import { Techniques } from "ui/pages/app/techniques/Techniques";
import { DefaultLayout } from "ui/pages/layouts/DefaultLayout";
import { RouteObject } from "react-router-dom";
import { Home } from "ui/pages/app/home/Home";
import {
  createSeanceLoader,
  homeLoader,
  myCreationsLoader,
  seanceLoader,
  seancesLoader,
  techniqueLoader,
  techniquesLoader,
} from "ui/navigation/routes/app/appLoaders";
import { Seances } from "ui/pages/app/seances/Seances";
import { Technique } from "ui/pages/app/techniques/technique/Technique";
import { Seance } from "ui/pages/app/seances/seance/Seance";
import { CreateSeance } from "ui/pages/app/seances/create-seance/CreateSeance";
import { MyCreations } from "ui/pages/app/my-creations/MyCreations";

export const appRoutes: RouteObject[] = [
  {
    index: true,
    loader: homeLoader,
    element: (
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    ),
  },
  {
    path: "/techniques",
    loader: techniquesLoader,
    element: (
      <DefaultLayout>
        <Techniques />
      </DefaultLayout>
    ),
  },
  {
    path: "/seances",
    loader: seancesLoader,
    element: (
      <DefaultLayout>
        <Seances />
      </DefaultLayout>
    ),
  },
  {
    path: "/technique/:id",
    loader: techniqueLoader,
    element: (
      <DefaultLayout>
        <Technique />
      </DefaultLayout>
    ),
  },
  {
    path: "/seance/:id",
    loader: seanceLoader,
    element: (
      <DefaultLayout>
        <Seance />
      </DefaultLayout>
    ),
  },
  {
    path: "/create-seance",
    loader: createSeanceLoader,
    element: (
      <div className="bg-darkGrey-100">
        <CreateSeance />
      </div>
    ),
  },
  {
    path: "/my-creations",
    loader: myCreationsLoader,
    element: (
      <DefaultLayout>
        <MyCreations />
      </DefaultLayout>
    ),
  },
];
