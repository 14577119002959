import { useState } from "react";

export const useWindowWidth = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  window.addEventListener("resize", () => {
    setIsDesktop(window.innerWidth > 768);
  });

  return isDesktop;
};
