import { HiArrowLeft } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { translate } from "ui/i18n";

export function BackButton({
  onClick,
  displayText = true,
}: {
  onClick: () => void;
  displayText?: boolean;
}) {
  const location = useLocation();

  return (
    <>
      {!routesWithoutBackButton.includes(location.pathname) && (
        <button onClick={onClick} className="z-50 flex h-5 items-center">
          <HiArrowLeft size={20} className="text-darkGrey-800" />
          {displayText && (
            <span className="ms-2 text-[14px] font-bold leading-none text-darkGrey-800">
              {translate("ns1:Button.Back")}
            </span>
          )}
        </button>
      )}
    </>
  );
}

const routesWithoutBackButton = ["/auth", "/tell-us-more"];
