import { Skeleton } from "@chakra-ui/react";

interface IProps {
  width?: string;
  height?: string;
  className?: string;
  borderRadius?: string;
}

export function INSkeleton({
  width = "100%",
  height = "250px",
  className,
  borderRadius = "20px",
}: IProps) {
  return (
    <Skeleton
      width={width}
      height={height}
      className={className}
      startColor="#c9d4ea"
      endColor="#e1e9f8"
      borderRadius={borderRadius}
    />
  );
}
