import {
  selectIsSeanceLoading,
  selectSeance,
  selectSeanceAdvice,
} from "domain/seances/seances.selectors";
import { HiArrowLeft, HiDownload, HiOutlineHeart } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { getDurationInHours } from "ui/utils/formatters";
import { SeanceBanner } from "ui/pages/app/seances/seance/SeanceBanner";
import { TechniqueComponent } from "ui/pages/app/seances/seance/TechniqueComponent";
import { useWindowWidth } from "ui/utils/hooks";
import { Advice } from "ui/pages/app/components/activities/Advice";
import { Note } from "ui/pages/app/components/activities/Note";

export function Seance() {
  const seance = useAppSelector(selectSeance);
  const advice = useAppSelector(selectSeanceAdvice);
  const isLoading = useAppSelector(selectIsSeanceLoading);
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();

  return (
    <div className="flex h-full flex-col p-4 md:flex-row md:py-8 md:pl-6 md:pr-10">
      <div className="my-4 flex w-full items-center justify-between md:my-0 md:w-12 md:items-start">
        <button
          onClick={() => navigate(-1)}
          className="z-50 flex h-5 items-center"
        >
          <HiArrowLeft size={24} className="text-darkGrey-800" />
        </button>
        {!isDesktop && (
          <div className="flex w-fit space-x-4">
            <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
              <HiOutlineHeart className="text-secondaryGrey-900" size={20} />
            </button>
            <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
              <HiDownload className="text-secondaryGrey-900" size={20} />
            </button>
          </div>
        )}
      </div>

      {seance && !isLoading ? (
        <>
          {" "}
          <div className="flex w-full flex-col space-y-6 md:space-y-8">
            <SeanceBanner seance={seance} />

            <div className="flex flex-wrap gap-x-3 gap-y-3 md:justify-start">
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-700 px-3 text-xs font-semibold text-white">
                {getDurationInHours(Number(seance.duration))}
              </div>
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                {seance.onsite &&
                  seance.remote &&
                  translate("ns1:Activities.remote&onsite")}
                {seance.onsite &&
                  !seance.remote &&
                  translate("ns1:Activities.onsite")}
                {!seance.onsite &&
                  seance.remote &&
                  translate("ns1:Activities.remote")}
              </div>
            </div>

            <div className="flex flex-col space-x-0 space-y-6 md:flex-row md:space-x-8 md:space-y-0">
              <div className="flex w-full flex-col space-y-6 md:space-y-8">
                <div className="rounded-[30px] bg-white p-4 py-6 md:p-6">
                  <span className="text-base font-medium md:text-sm">
                    {`${translate("ns1:Activities.Goal")}: ${seance.objectif}`}
                  </span>
                  <span className="mt-4 block text-sm font-medium">
                    {translate("ns1:Activities.ActivitySteps")}
                  </span>
                  {seance.techniques.length > 0 &&
                    seance.techniques.map((technique, index) => (
                      <TechniqueComponent
                        key={"technique" + index}
                        technique={technique}
                        index={index}
                        techniquesLength={seance.techniques.length}
                      />
                    ))}
                </div>

                {/* advice and equipment for mobile */}
                {!isDesktop && (advice || seance.note) && (
                  <div className="flex w-80 flex-col space-y-6 md:space-y-8">
                    {seance.note && <Note note={seance.note} />}
                    {/* <Equipment /> */}
                    {advice && <Advice advice={advice} />}
                  </div>
                )}
              </div>

              {/* advice and equipment for desktop */}
              {isDesktop && (advice || seance.note) && (
                <div className="flex w-80 flex-col space-y-6 md:space-y-8">
                  {seance.note && <Note note={seance.note} />}
                  {/* <Equipment /> */}
                  {advice && <Advice advice={advice} />}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <div className="loader" />
        </div>
      )}
    </div>
  );
}
