export function CardBgPink400({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5653)">
        <rect width="80" height="80" rx="8" fill="#E14277" />
        <g clipPath="url(#clip1_266_5653)">
          <rect
            x="28.0771"
            y="68.936"
            width="8"
            height="8"
            rx="4"
            transform="rotate(-70 28.0771 68.936)"
            fill="#EC7598"
          />
          <rect
            x="11.1914"
            y="21.5444"
            width="14"
            height="14"
            rx="7"
            transform="rotate(-70 11.1914 21.5444)"
            fill="#EC7598"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0005 -7.87522C18.8564 -5.83863 17.8993 -3.49379 15.8627 -2.63788C13.6529 -1.70918 8.67323 0.865738 4.4939 5.23823C0.383797 9.5383 -2.81644 15.4041 -2.15719 23.2301C-1.49056 31.1437 2.85353 37.4098 7.97889 41.8981C8.70879 42.5373 9.449 43.1351 10.189 43.6912C12.6599 40.7995 15.5494 38.5557 18.4902 37.2569C22.4851 35.4925 26.8502 35.3804 30.3612 36.901C33.9929 38.4739 36.8167 41.9517 36.3987 46.6642C36.0165 50.9739 33.413 53.9371 30.1126 55.5262C26.9735 57.0375 23.2025 57.3525 19.6747 56.9043C17.7238 56.6564 15.2678 55.8156 12.7228 54.5868C12.0304 56.1897 11.5102 57.9152 11.2352 59.7209C10.2077 66.4688 12.5796 74.8152 24.1169 81.8711C26.0016 83.0237 26.595 85.4859 25.4424 87.3705C24.2898 89.2551 21.8276 89.8486 19.943 88.696C5.98355 80.1587 1.75898 68.8098 3.32641 58.5166C3.75986 55.6702 4.63591 52.9386 5.8327 50.4204C4.78625 49.6536 3.73878 48.8189 2.70843 47.9166C-3.43116 42.5401 -9.23584 34.5038 -10.129 23.9017C-11.0294 13.212 -6.53195 5.19557 -1.28925 -0.289438C3.88422 -5.70203 9.91243 -8.81498 12.7632 -10.013C14.7998 -10.8689 17.1446 -9.91182 18.0005 -7.87522ZM17.2209 47.851C18.825 48.549 20.0333 48.8855 20.6831 48.9681C23.1798 49.2853 25.2886 48.9698 26.6422 48.3181C27.8344 47.7441 28.3364 47.0119 28.43 45.9575C28.4878 45.3059 28.2679 44.7125 27.1818 44.2421C25.975 43.7194 23.9481 43.5919 21.7222 44.575C20.2522 45.2242 18.6873 46.3536 17.2209 47.851Z"
            fill="#F8AEBC"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_266_5653">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_266_5653">
          <rect
            width="120"
            height="100"
            fill="white"
            transform="translate(-60 -10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
