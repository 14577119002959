export function CardBgPink200({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5859)">
        <rect width="280" height="164" rx="20" fill="#F8AEBC" />
        <g clipPath="url(#clip1_77_5859)">
          <rect
            width="28.2646"
            height="27.7233"
            rx="13.8617"
            transform="matrix(0.885819 -0.46403 0.533668 0.845694 96 107.871)"
            fill="#EC7598"
          />
          <rect
            width="19.0444"
            height="18.6797"
            rx="9.33985"
            transform="matrix(0.885819 -0.46403 0.533668 0.845694 42.9998 95.1152)"
            fill="#EC7598"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.818 148.495C128.876 155.735 145 166.694 145 184.044C145 189.076 140.523 193.156 135 193.156C129.477 193.156 125 189.076 125 184.044C125 178.389 119.686 171.923 103.948 164.827C89.0263 158.1 68.8733 152.686 47.9727 147.315C46.3072 146.887 44.6352 146.459 42.9605 146.03C24.1948 141.225 5.09273 136.334 -8.98028 130.522C-16.603 127.374 -23.8637 123.528 -28.8434 118.512C-34.2576 113.058 -37.1917 105.883 -34.6111 97.7043C-30.2371 83.8428 -17.7319 78.283 -5.37814 76.2883C6.22147 74.4153 20.0905 75.217 32.293 75.9224C32.6362 75.9422 32.978 75.962 33.3185 75.9816C46.5913 76.7466 57.8325 77.3084 66.204 75.7842C70.2253 75.052 72.7209 73.9689 74.2343 72.8158C75.5327 71.8266 76.638 70.4015 77.1423 67.7106C77.3626 66.5349 77.2219 65.3741 75.1625 63.4703C72.7381 61.2291 68.3583 58.7494 61.5853 56.1244C50.7115 51.9101 36.4986 48.3262 20.6969 44.3416C16.7715 43.3518 12.748 42.3372 8.65331 41.2821C-11.2702 36.1484 -32.6553 30.0924 -49.1169 21.3075C-65.6265 12.4969 -79.5002 -0.377211 -79.5002 -19.5892C-79.5002 -24.6211 -75.023 -28.7003 -69.5002 -28.7003C-63.9773 -28.7003 -59.5002 -24.6211 -59.5002 -19.5892C-59.5002 -9.87341 -52.9364 -1.84886 -39.0087 5.58388C-25.0328 13.0423 -5.98041 18.5745 14.0961 23.7476C17.8114 24.705 21.5765 25.6529 25.3422 26.6009C41.2621 30.6089 57.1924 34.6195 69.414 39.3562C76.9848 42.2905 84.2299 45.8932 89.4071 50.6792C94.9493 55.8027 98.3867 62.6141 96.857 70.7773C95.6113 77.4253 92.2635 82.8335 87.0638 86.7949C82.0792 90.5925 76.0318 92.5773 70.1234 93.6531C58.8182 95.7115 44.9204 94.9097 32.6495 94.2017L32.0558 94.1674C18.8144 93.4043 7.17032 92.7683 -1.88821 94.2309C-10.5188 95.6244 -14.0137 98.3785 -15.3897 102.739C-15.684 103.672 -15.7744 104.512 -14.0108 106.288C-11.8127 108.502 -7.56171 111.101 -0.723445 113.925C11.8838 119.132 29.5063 123.65 48.8376 128.606C50.3492 128.993 51.8711 129.383 53.402 129.777C73.9389 135.054 95.9423 140.887 112.818 148.495Z"
            fill="#FFD3DC"
            fillOpacity="0.996078"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_5859">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_5859">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="translate(-120 -31.8889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
