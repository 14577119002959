import { Technique } from "domain/entities/techniques.entities";
import { useEffect, useState } from "react";
import {
  HiChevronDown,
  HiCollection,
  HiOutlineExclamation,
} from "react-icons/hi";
import { translate } from "ui/i18n";
import { TechniqueCard } from "ui/pages/app/components/activities/TechniqueCard";
import { getDurationInHours } from "ui/utils/formatters";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  selectedTechniques: Technique[];
  duration: number;
  updateSelectedTechniques: (
    technique: Technique,
    action: "add" | "remove"
  ) => void;
  currentDuration: number;
}

export function SelectionZone({
  selectedTechniques,
  duration,
  updateSelectedTechniques,
  currentDuration,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useWindowWidth();

  useEffect(() => {
    console.log(selectedTechniques);
  }, [selectedTechniques]);

  const getFullDuration = () => {
    let total = currentDuration;
    selectedTechniques.forEach((t) => (total += Number(t.duration)));
    return total;
  };

  return (
    <div
      className="relative max-h-dvh cursor-pointer overflow-y-auto bg-darkGrey-700 px-2 py-4 shadow-lg md:px-12 md:px-6"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex items-center">
        <div
          className={`flex w-full items-center ${
            isOpen || !isDesktop
              ? "justify-between"
              : "justify-center gap-x-3 md:gap-x-6"
          }`}
        >
          <div className="flex items-center">
            <HiCollection color="#fff" size={32} />
            <p
              className={`ms-3 flex  text-base font-normal text-white md:ms-6 ${
                isDesktop ? "flex-row" : "flex-col"
              }`}
            >
              <span>
                {selectedTechniques.length}{" "}
                {translate(
                  selectedTechniques.length > 1
                    ? "ns1:CreateSeance.MultipleSelected"
                    : "ns1:CreateSeance.OneSelected"
                )}
                {selectedTechniques.length > 0 && ":"}
              </span>

              {selectedTechniques.length > 0 && (
                <span className="ms-1 flex">
                  <span className="font-bold">
                    {getDurationInHours(getFullDuration())}
                  </span>
                  / {getDurationInHours(duration)}
                  {getFullDuration() > duration && (
                    <HiOutlineExclamation
                      size={24}
                      color="#fff"
                      className="ms-2"
                    />
                  )}
                </span>
              )}
            </p>
          </div>
          <HiChevronDown
            size={24}
            color="#fff"
            className={`${isOpen ? "popover-open" : "popover-close"}`}
          />
        </div>
      </div>

      {isOpen && (
        <p className="mt-5 text-base font-normal text-white">
          {translate("ns1:CreateSeance.RemainingTime")}:{" "}
          {getFullDuration() < duration
            ? getDurationInHours(duration - getFullDuration())
            : getDurationInHours(0)}
        </p>
      )}

      {isOpen && selectedTechniques.length > 0 && (
        <div className="mt-4 grid w-full grid-cols-1 gap-2 md:mt-8 md:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {selectedTechniques.map((technique, index) => (
            <TechniqueCard
              technique={technique}
              key={"technique" + index}
              selection
              onSelect={() => updateSelectedTechniques(technique, "remove")}
              selected
              inSelectionZone
              row={!isDesktop}
              favButton={false}
            />
          ))}
        </div>
      )}
    </div>
  );
}
