export function CardBgLightGreen400({
  width = 80,
  height = 80,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_5738)">
        <rect width="80" height="80" rx="8" fill="#68D8C9" />
        <g clipPath="url(#clip1_266_5738)">
          <path
            d="M32.1775 15.4734C33.7403 15.6316 35.1355 14.4928 35.2937 12.9299L35.3327 12.544C35.7856 8.06843 32.5245 4.07316 28.049 3.62031C23.5734 3.16746 19.5781 6.42851 19.1253 10.9041L19.0862 11.29C18.9281 12.8529 20.0669 14.248 21.6298 14.4062L32.1775 15.4734Z"
            fill="#53BBAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.584 55.7049C27.9185 59.686 28.0162 67.316 16.3439 83.6772C15.0609 85.4756 15.4787 87.9736 17.2771 89.2566C19.0755 90.5396 21.5734 90.1218 22.8564 88.3234C34.5841 71.8846 37.5318 60.2646 32.4851 51.6582C30.079 47.5551 26.1708 44.804 22.1204 42.7436C18.2978 40.7992 13.913 39.2627 9.8199 37.8284C9.57876 37.7439 9.33864 37.6598 9.0997 37.5759C4.67097 36.0218 0.607448 34.5549 -2.77677 32.7106C-6.14627 30.8744 -8.43374 28.8755 -9.67475 26.495C-12.0305 21.9762 -11.7268 13.528 0.282439 -3.71352C1.54508 -5.52626 1.09912 -8.01935 -0.713626 -9.28198C-2.52637 -10.5446 -5.01946 -10.0987 -6.28209 -8.28592C-18.3728 9.07253 -21.4442 21.2244 -16.7687 30.1931C-14.494 34.5563 -10.6643 37.523 -6.6049 39.7353C-2.56021 41.9394 2.11689 43.6038 6.45066 45.1246C6.62977 45.1875 6.80829 45.2501 6.98622 45.3125C11.2467 46.8067 15.1637 48.1804 18.4933 49.8741C21.9593 51.6372 24.2995 53.5142 25.584 55.7049Z"
            fill="#B5F6EC"
          />
          <path
            d="M-3.08195 75.7387C-2.78778 77.2818 -1.2984 78.2942 0.24469 78.0001L0.625706 77.9274C5.04454 77.0851 7.94383 72.82 7.10145 68.4012C6.25907 63.9823 1.99401 61.0831 -2.42482 61.9254L-2.80583 61.9981C-4.34892 62.2922 -5.36138 63.7816 -5.06721 65.3247L-3.08195 75.7387Z"
            fill="#53BBAD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_266_5738">
          <rect width="80" height="80" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_266_5738">
          <rect
            width="120"
            height="100"
            fill="white"
            transform="translate(-60 -10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
