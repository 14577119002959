import { Seance } from "domain/entities/seances.entities";
import {
  selectIsSeancesLoading,
  selectSeances,
} from "domain/seances/seances.selectors";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { PageHeader } from "ui/pages/app/components/PageHeader";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { SeanceCard } from "ui/pages/app/components/activities/SeanceCard";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import { EmptyCreations } from "ui/assets/svg/EmptyCreations";
import { ModalCreateRessource } from "ui/pages/app/my-creations/components/ModalCreateRessource";
import { useDisclosure } from "@chakra-ui/hooks";

export function MyCreations() {
  const modalCreateDisclosure = useDisclosure();
  const isLoading = useAppSelector(selectIsSeancesLoading);
  const seances = useAppSelector(selectSeances);
  const navigate = useNavigate();

  const handleCardClick = async (seance: Seance) => {
    seance.active
      ? navigate(`/seance/${seance?.id}`)
      : navigate("/create-seance", { state: { seanceId: seance.id } });
  };

  return (
    <div className={`flex h-full w-full flex-row overflow-x-hidden`}>
      <div className="h-full w-full px-2 pt-2 md:px-12 md:pt-8">
        <PageHeader title={translate("ns1:Routes.MyCreations")} />
        <PrimaryButton
          height="52px"
          className="mt-4"
          icon={<HiPlus size={20} color="#fff" />}
          onClick={modalCreateDisclosure.onOpen}
        >
          {translate("ns1:MyCreations.CreateAResource")}
        </PrimaryButton>

        {seances.length > 0 ? (
          <>
            <h2 className="mt-10 ps-5 text-secondaryGrey-900">
              {translate("ns1:MyCreations.MySeances")}
            </h2>

            <div className="mb-8 mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
              {!isLoading ? (
                <>
                  {seances.map((seance, index) => (
                    <SeanceCard
                      seance={seance}
                      key={"seance" + index}
                      onClick={() => handleCardClick(seance)}
                    />
                  ))}
                </>
              ) : (
                <>
                  {[...Array(4)].map((_, index) => (
                    <INSkeleton key={"seances-skeleton" + index} />
                  ))}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="mt-[120px] flex flex-1 flex-col items-center text-center text-base text-darkGrey-700">
            <p className="font-bold">
              {translate("ns1:MyCreations.NoRessouceCreated")}
            </p>
            <p className="font-medium">
              {translate("ns1:MyCreations.ClickCreateARessource")}
            </p>
            <div className="mt-16" style={{ maxWidth: 500, width: "100%" }}>
              <EmptyCreations />
            </div>
          </div>
        )}
      </div>

      <ModalCreateRessource
        isOpen={modalCreateDisclosure.isOpen}
        onClose={modalCreateDisclosure.onClose}
      />
    </div>
  );
}
