export function CardBgGrey400({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5958)">
        <rect width="280" height="164" rx="20" fill="#657F91" />
        <g clipPath="url(#clip1_77_5958)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-34.7927 -15.2729C-32.1623 -19.6974 -26.0932 -21.3414 -21.237 -18.9447C4.20013 -6.39105 27.9079 12.4026 45.1915 40.082C62.4555 67.7299 72.9992 103.742 72.9992 150.333C72.9992 155.365 68.5221 159.444 62.9992 159.444C57.4764 159.444 52.9992 155.365 52.9992 150.333C52.9992 106.269 43.0434 73.4921 27.8079 49.0928C12.5921 24.725 -8.19972 8.21313 -30.7626 -2.92208C-35.6188 -5.3187 -37.4232 -10.8483 -34.7927 -15.2729Z"
            fill="#9CBBCF"
          />
          <path
            d="M179.332 76.9713C178.302 72.0277 173.434 68.7175 168.458 69.578L147.179 73.2575C142.203 74.1179 139.004 78.8231 140.034 83.7667C141.063 88.7104 145.932 92.0205 150.908 91.1601L172.187 87.4806C177.163 86.6202 180.362 81.915 179.332 76.9713Z"
            fill="#7A97AA"
          />
          <path
            d="M128.573 125.21C129.981 130.076 135.115 133.061 140.04 131.877L141.544 131.516C146.469 130.333 149.32 125.429 147.911 120.563C146.503 115.698 141.369 112.713 136.444 113.896L134.94 114.258C130.015 115.441 127.164 120.345 128.573 125.21Z"
            fill="#7A97AA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M107.5 80.1777C113.023 80.1777 117.5 84.2569 117.5 89.2888C117.5 92.6924 116.116 98.9024 114.206 105.401C112.174 112.312 109.183 120.75 105.315 129.053C101.483 137.28 96.5969 145.794 90.6252 152.567C84.8173 159.154 76.7661 165.457 66.3693 166.436C57.1824 167.302 47.3874 164.424 38.861 160.826C30.059 157.112 21.2141 152.02 13.4724 147.029C5.68847 142.011 -1.22031 136.937 -6.17026 133.131C-8.65156 131.223 -10.656 129.622 -12.0494 128.49C-12.7464 127.924 -13.2915 127.474 -13.6679 127.161C-13.8562 127.005 -14.0023 126.883 -14.1043 126.797L-14.2239 126.696L-14.273 126.655C-14.2745 126.654 -14.2757 126.653 -7.46261 119.983L-14.2757 126.653C-18.3184 123.224 -18.5453 117.459 -14.7825 113.776C-11.0202 110.093 -4.69378 109.886 -0.651028 113.313L-0.649702 113.314L-0.633045 113.328L-0.549902 113.398C-0.472584 113.463 -0.351879 113.564 -0.189848 113.698C0.134268 113.968 0.623375 114.371 1.26095 114.889C2.53674 115.926 4.40319 117.417 6.72839 119.205C11.3914 122.79 17.8384 127.521 25.0251 132.154C32.2542 136.815 39.9949 141.223 47.2661 144.291C54.8128 147.476 60.5782 148.663 64.3115 148.311C66.8349 148.073 70.5137 146.281 75.0332 141.155C79.3889 136.215 83.4279 129.398 86.905 121.933C90.347 114.545 93.0512 106.93 94.8867 100.687C95.803 97.57 96.4835 94.8607 96.927 92.7532C97.149 91.6983 97.3016 90.8413 97.3956 90.1901C97.4755 89.6361 97.4942 89.3584 97.4985 89.2939C97.4997 89.2757 97.4998 89.2745 97.4998 89.2888C97.4998 84.2569 101.977 80.1777 107.5 80.1777Z"
            fill="#9CBBCF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_5958">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_5958">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="translate(-120 -31.8889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
