export function CardBgLightGreen400({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5925)">
        <rect width="280" height="164" rx="20" fill="#68D8C9" />
        <g clipPath="url(#clip1_77_5925)">
          <path
            d="M111.06 26.19C114.627 26.5188 117.839 23.8995 118.234 20.3396C119.42 9.65978 111.708 0.202443 101.007 -0.783986L99.2373 -0.947176C88.5372 -1.9336 78.9016 5.92442 77.7156 16.6042C77.3202 20.1641 79.8911 23.3166 83.4578 23.6454L111.06 26.19Z"
            fill="#53BBAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.9596 117.772C99.7958 126.84 100.04 144.219 70.8592 181.486C67.6517 185.583 68.6963 191.273 73.1922 194.195C77.6882 197.117 83.9331 196.166 87.1406 192.069C116.46 154.625 123.829 128.158 111.212 108.554C105.197 99.2082 95.4264 92.9418 85.3005 88.2488C75.7441 83.8198 64.7819 80.32 54.5493 77.0531C53.9464 76.8606 53.3461 76.669 52.7488 76.478C41.6769 72.938 31.5181 69.5967 23.0576 65.3959C14.6338 61.2134 8.91516 56.6603 5.81264 51.238C-0.076643 40.9452 0.682373 21.7022 30.7056 -17.5702C33.8622 -21.6992 32.7473 -27.3779 28.2154 -30.2539C23.6836 -33.1299 17.4508 -32.1142 14.2943 -27.9851C-15.9325 11.5536 -23.611 39.2328 -11.9221 59.6616C-6.23557 69.6 3.33872 76.3575 13.4873 81.3964C23.599 86.4171 35.2917 90.2081 46.1262 93.6722C46.5739 93.8154 47.0202 93.958 47.465 94.1001C58.1162 97.5036 67.9086 100.633 76.2327 104.491C84.8977 108.506 90.7482 112.782 93.9596 117.772Z"
            fill="#B5F6EC"
          />
          <path
            d="M22.174 162.829C22.9759 166.662 26.7533 169.225 30.611 168.555L33.2215 168.102C43.3529 166.342 49.8588 156.756 47.7529 146.691C45.6469 136.626 35.7266 129.893 25.5952 131.653L22.9847 132.106C19.127 132.776 16.6497 136.426 17.4516 140.259L22.174 162.829Z"
            fill="#53BBAD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_5925">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_5925">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="translate(-120 -31.8889)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
