export function BannerPink400({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8858)">
        <rect width="1080" height="210" fill="#E14277" />
        <g clipPath="url(#clip1_779_8858)">
          <rect
            x="850.193"
            y="177.34"
            width="20"
            height="20"
            rx="10"
            transform="rotate(-70 850.193 177.34)"
            fill="#EC7598"
          />
          <rect
            x="807.979"
            y="58.8611"
            width="35"
            height="35"
            rx="17.5"
            transform="rotate(-70 807.979 58.8611)"
            fill="#EC7598"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M825.001 -14.6881C827.141 -9.59657 824.748 -3.73447 819.657 -1.5947C814.132 0.727044 801.683 7.16434 791.235 18.0956C780.96 28.8458 772.959 43.5104 774.607 63.0753C776.274 82.8591 787.134 98.5244 799.947 109.745C801.772 111.343 803.623 112.838 805.473 114.228C811.65 106.999 818.874 101.389 826.226 98.1423C836.213 93.7313 847.126 93.451 855.903 97.2526C864.982 101.185 872.042 109.879 870.997 121.661C870.041 132.435 863.533 139.843 855.282 143.815C847.434 147.594 838.006 148.381 829.187 147.261C824.31 146.641 818.17 144.539 811.807 141.467C810.076 145.474 808.776 149.788 808.088 154.302C805.519 171.172 811.449 192.038 840.293 209.678C845.004 212.559 846.488 218.715 843.606 223.426C840.725 228.138 834.569 229.621 829.858 226.74C794.959 205.397 784.398 177.025 788.316 151.291C789.4 144.175 791.59 137.346 794.582 131.051C791.966 129.134 789.347 127.047 786.771 124.791C771.422 111.35 756.911 91.2595 754.678 64.7542C752.427 38.0301 763.67 17.9889 776.777 4.2764C789.711 -9.25508 804.781 -17.0374 811.908 -20.0326C817 -22.1724 822.862 -19.7795 825.001 -14.6881ZM823.052 124.627C827.063 126.373 830.084 127.214 831.708 127.42C837.95 128.213 843.222 127.424 846.606 125.795C849.586 124.36 850.841 122.53 851.075 119.894C851.22 118.265 850.67 116.781 847.955 115.605C844.938 114.299 839.871 113.98 834.306 116.437C830.631 118.061 826.719 120.884 823.052 124.627Z"
            fill="#F8AEBC"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8858">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8858">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(630 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
