import { isRouteErrorResponse } from "react-router-dom";
import { translate } from "ui/i18n";

export function formatError(error: unknown) {
  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    return `${error.status} ${error.statusText}`;
  } else if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "string") {
    return error;
  } else {
    console.error(error);
    return translate("ns1:Errors.UnknownError");
  }
}
