interface IProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  width?: string;
  height?: string;
  icon?: React.ReactNode;
  outlined?: boolean;
  className?: string;
}

export function SecondaryButton({
  children,
  onClick,
  disabled = false,
  type,
  width,
  height = "36px",
  icon,
  outlined,
  className,
}: IProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type || "button"}
      className={`disabled:text-darkGrey-500" flex items-center justify-center rounded-xl border border-none bg-white text-[14px] font-bold text-darkGrey-800 hover:bg-darkGrey-100
      disabled:border-darkGrey-500 ${className}`}
      style={{
        width,
        height,
        padding: width ? 0 : "8px 16px",
      }}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </button>
  );
}
