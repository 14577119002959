import { useState } from "react";
import { Sidebar } from "ui/pages/layouts/components/Sidebar";
import { useWindowWidth } from "ui/utils/hooks";
import { SidebarContext } from "ui/pages/layouts/components/SidebarContext";

interface IProps {
  children?: React.ReactNode;
}

export function DefaultLayout({ children }: IProps) {
  const isDesktop = useWindowWidth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      <div className="w-screen overflow-x-hidden md:w-full">
        <div
          className={` ${
            !isDesktop &&
            (isSidebarOpen
              ? "sidebar-open h-dvh overflow-x-hidden overflow-y-hidden"
              : "sidebar-closed")
          }`}
          style={{ width: isDesktop ? "100%" : "180vw" }}
        >
          <div className="flex">
            <Sidebar />
            <div
              className="z-10 ml-0 min-h-dvh bg-darkGrey-100 md:ml-56"
              style={{
                width: isDesktop ? "calc(100% - 14rem)" : "100vw",
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </SidebarContext.Provider>
  );
}
