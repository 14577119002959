export function BannerPaleGreen200({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8846)">
        <rect width="1080" height="210" fill="#B5DDD6" />
        <path
          d="M720.269 67.0573C725.525 65.3613 731.161 68.2471 732.857 73.5031L738.429 90.7695C740.125 96.0255 737.239 101.661 731.983 103.357L714.717 108.929C709.461 110.625 703.825 107.739 702.129 102.483L696.557 85.2168C694.861 79.9608 697.747 74.3251 703.003 72.629L720.269 67.0573Z"
          fill="#96BCB6"
        />
        <path
          d="M763.839 20.3613C768.622 23.1227 770.26 29.2387 767.499 34.0216L763.851 40.3397C761.09 45.1226 754.974 46.7614 750.191 44L743.873 40.3522C739.09 37.5908 737.451 31.4749 740.213 26.6919L743.86 20.3738C746.622 15.5909 752.738 13.9521 757.521 16.7136L763.839 20.3613Z"
          fill="#96BCB6"
        />
        <path
          d="M885.84 152.361C890.623 155.123 892.261 161.239 889.5 166.022L885.852 172.34C883.091 177.123 876.975 178.761 872.192 176L865.874 172.352C861.091 169.591 859.452 163.475 862.214 158.692L865.861 152.374C868.623 147.591 874.739 145.952 879.522 148.714L885.84 152.361Z"
          fill="#96BCB6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M792.5 -17C786.977 -17 782.5 -12.5229 782.5 -7.00002L782.5 82L748.594 82C703.129 82 678.083 134.819 706.856 170.021C738.92 209.25 802.5 186.601 802.5 135.92L802.5 102L818.075 102C833.774 102 846.501 114.727 846.501 130.426L846.501 190.43C846.501 191.261 846.516 192.24 846.594 193.294C846.855 196.868 847.888 207.269 851.663 219.87C853.248 225.16 858.821 228.164 864.112 226.579C869.402 224.994 872.406 219.421 870.821 214.13C867.595 203.359 866.735 194.495 866.54 191.834C866.513 191.464 866.501 191.025 866.501 190.43L866.501 130.426C866.501 103.681 844.82 82 818.075 82L802.5 82L802.5 -7.00001C802.5 -12.5229 798.023 -17 792.5 -17ZM782.5 102L782.5 135.92C782.5 167.785 742.517 182.048 722.341 157.364C704.244 135.223 719.997 102 748.594 102L782.5 102Z"
          fill="#CCF4ED"
        />
      </g>
      <defs>
        <clipPath id="clip0_779_8846">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
