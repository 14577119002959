import { useForm } from "react-hook-form";
import { translate } from "ui/i18n";
import { INInput } from "ui/pages/app/components/INInput";
import { Inputs } from "ui/pages/inputsType";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { INRadioButtons } from "ui/pages/app/components/INRadioButtons";
import { INCheckbox } from "../../app/components/INCheckbox";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { updateUser, verifyUser } from "domain/auth/auth.actions";
import { selectIsAuthenticationLoading } from "domain/auth/auth.selectors";
import { Snackbar } from "ui/pages/snackbar";

export function TellUsMore() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAuthenticationLoading);

  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) navigate("/auth");
  }, [token]); //eslint-disable-line

  const { register, handleSubmit, formState, control } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      lastName: "",
      firstName: "",
      jobRole: "",
      newsletter: "",
    },
  });

  const { errors, isValid, defaultValues } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const resultVerify = await dispatch(
      verifyUser({ action: "verifySignupLong", value: token })
    ).unwrap();
    if (resultVerify.id && resultVerify.email) {
      const resultUpdate = await dispatch(
        updateUser({
          data: {
            firstName: data.firstName,
            lastName: data.lastName,
            jobRole: data.jobRole,
            newsletter: data.newsletter || false,
          },
          userId: resultVerify.id,
        })
      ).unwrap();
      if (resultUpdate.success) {
        Snackbar.showSuccess({
          title: translate("ns1:TellUsMore.AccountValidated"),
        });
        navigate("/login", { state: { email: resultVerify.email } });
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">
        {translate("ns1:TellUsMore.TellUsMoreAboutYourself")}
      </h2>
      <h4 className="mt-2 text-center md:mt-4">
        {translate("ns1:TellUsMore.ToOfferYouAdvices")}
      </h4>

      <div className="w-full max-w-[530px]">
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="mb-4 mt-6 md:mb-8 md:mt-12"
        >
          <INInput
            error={errors.lastName}
            label={translate("ns1:Input.Labels.Name")}
            placeholder={translate("ns1:Input.Labels.LastName")}
            register={{
              ...register("lastName", {
                required: translate("ns1:Input.Errors.Required"),
              }),
            }}
            className="mb-2 md:mb-4"
          />
          <INInput
            error={errors.firstName}
            label={translate("ns1:Input.Labels.FirstName")}
            placeholder={translate("ns1:Input.Labels.FirstName")}
            register={{
              ...register("firstName", {
                required: translate("ns1:Input.Errors.Required"),
              }),
            }}
            className="mb-2 md:mb-4"
          />
          <INRadioButtons
            inputName="jobRole"
            options={jobOptions}
            label={translate("ns1:Input.Labels.WhatIsYourJob")}
            defaultValue={defaultValues.jobRole}
            className="mb-2 md:mb-4"
            error={errors.jobRole}
            control={control}
            required
          />

          <INCheckbox
            inputName="newsletter"
            control={control}
            text={translate("ns1:TellUsMore.IWouldLikeToBeInformed")}
            label={translate("ns1:Input.Labels.Preferences")}
          />
        </form>
        <PrimaryButton
          disabled={!isValid || isLoading}
          width="100%"
          height="52px"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {translate("ns1:Button.Next")}
        </PrimaryButton>
      </div>
    </div>
  );
}

const jobOptions = [
  {
    name: translate("ns1:TellUsMore.JobOptions.Consultant"),
    value: "consultant",
  },
  {
    name: translate("ns1:TellUsMore.JobOptions.Designer"),
    value: "designer",
  },
  {
    name: translate("ns1:TellUsMore.JobOptions.Facilitator"),
    value: "facilitator",
  },
  {
    name: translate("ns1:TellUsMore.JobOptions.ProjectManager"),
    value: "projectManager",
  },
  {
    name: translate("ns1:TellUsMore.JobOptions.Manager"),
    value: "manager",
  },
  {
    name: translate("ns1:TellUsMore.JobOptions.Former"),
    value: "former",
  },
  {
    name: translate("ns1:TellUsMore.JobOptions.Coach"),
    value: "coach",
  },
];
