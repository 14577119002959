export function CardBgPaleGreen200({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5826)">
        <rect width="280" height="164" rx="20" fill="#B5DDD6" />
        <path
          d="M13.649 4.78495C18.5369 7.3561 20.1028 13.2222 17.1467 17.8873L14.2041 22.531C11.2479 27.1961 4.88912 28.8935 0.00127249 26.3224L-5.93692 23.1987C-10.8248 20.6275 -12.3907 14.7614 -9.43455 10.0963L-6.49196 5.45267C-3.53581 0.787589 2.82301 -0.909875 7.71086 1.66128L13.649 4.78495Z"
          fill="#96BCB6"
        />
        <path
          d="M135.65 125.052C140.538 127.623 142.104 133.489 139.147 138.154L136.205 142.798C133.249 147.463 126.89 149.16 122.002 146.589L116.064 143.465C111.176 140.894 109.61 135.028 112.566 130.363L115.509 125.719C118.465 121.054 124.824 119.357 129.712 121.928L135.65 125.052Z"
          fill="#96BCB6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.5 -29.1555C36.9771 -29.1555 32.5 -25.0763 32.5 -20.0444L32.5 61.0444L-1.40602 61.0444C-46.8703 61.0444 -71.9163 109.169 -43.1438 141.241C-11.0797 176.983 52.4999 156.348 52.4999 110.172L52.4999 79.2667L68.0749 79.2667C83.7743 79.2667 96.5012 90.8623 96.5012 105.166L96.5011 159.836C96.5011 160.594 96.5167 161.486 96.5939 162.446C96.8555 165.702 97.8879 175.178 101.663 186.659C103.248 191.479 108.821 194.216 114.112 192.772C119.403 191.328 122.407 186.25 120.822 181.43C117.595 171.616 116.735 163.54 116.54 161.115C116.513 160.779 116.501 160.378 116.501 159.836L116.501 105.166C116.501 80.7984 94.82 61.0444 68.0749 61.0444L52.5 61.0444L52.5 -20.0444C52.5 -25.0763 48.0228 -29.1555 42.5 -29.1555ZM32.4999 79.2667L32.4999 110.172C32.4999 139.204 -7.48267 152.199 -27.6585 129.709C-45.7559 109.536 -30.0024 79.2667 -1.40602 79.2667L32.4999 79.2667Z"
          fill="#CCF4ED"
        />
      </g>
      <defs>
        <clipPath id="clip0_77_5826">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
