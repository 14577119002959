import {
  selectIsPremium,
  selectUserId,
  selectUserName,
} from "domain/auth/auth.selectors";
import { Seance } from "domain/entities/seances.entities";
import { HiLockClosed, HiOutlineHeart } from "react-icons/hi";
import { CardBgGrey300 } from "ui/assets/svg/seance-card-backgrounds/CardBgGrey300";
import { CardBgLightGreen500 } from "ui/assets/svg/seance-card-backgrounds/CardBgLightGreen500";
import { CardBgPaleGreen200 } from "ui/assets/svg/seance-card-backgrounds/CardBgPaleGreen200";
import { CardBgPaleGreen400 } from "ui/assets/svg/seance-card-backgrounds/CardBgPaleGreen400";
import { CardBgPink200 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink200";
import { CardBgPink300 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink300";
import { CardBgPink400 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink400";
import { CardBgPink500 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink500";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { getDurationInHours } from "ui/utils/formatters";
import { MouseEvent } from "react";

interface IProps {
  seance: Seance;
  onClick: () => void;
  onBookmark?: (seance: Seance) => void;
  bookmarked?: boolean;
}

export function SeanceCard({
  seance,
  onClick,
  onBookmark,
  bookmarked,
}: IProps) {
  const isPremium = useAppSelector(selectIsPremium);
  const userName = useAppSelector(selectUserName);
  const userId = useAppSelector(selectUserId);
  const CardImage = getCardImage(Number(seance.duration));

  const handleCardClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const handleBookmarkClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onBookmark && (isPremium || !seance.premium)) {
      onBookmark(seance);
    }
  };

  return (
    <div
      className={`flex h-full cursor-pointer flex-col justify-between rounded-[20px] bg-white p-3 md:p-5 ${
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={handleCardClick}
    >
      <div>
        <div className="relative">
          <div
            className={
              (!isPremium && !!seance.premium) || !seance.active
                ? "opacity-30"
                : undefined
            }
          >
            <CardImage width="100%" height="100%" />
          </div>
          {!seance.active && (
            <div className="absolute left-2 top-2 flex rounded-[36px] bg-secondaryGrey-800 px-2.5 py-0.5 text-xs font-semibold uppercase text-white md:left-4 md:top-4">
              {translate("ns1:MyCreations.Draft")}
            </div>
          )}
          <button
            disabled={!seance.active}
            className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full bg-white disabled:opacity-40 md:right-4 md:top-4"
            onClick={handleBookmarkClick}
          >
            {!isPremium && !!seance.premium ? (
              <HiLockClosed className="text-secondaryGrey-900" size={18} />
            ) : (
              <HiOutlineHeart className="text-secondaryGrey-900" size={18} />
            )}
          </button>
        </div>

        <span
          className={`mt-2 block text-base font-bold ${
            !isPremium && !!seance.premium && "opacity-30"
          }`}
        >
          {seance.title}
        </span>
      </div>

      <div className="mt-3 flex items-center space-x-2 md:mt-8">
        {seance.userId && seance.userId === userId && (
          <span className="flex h-7 w-7 items-center justify-center rounded-full bg-secondaryGrey-800 text-xs font-semibold uppercase text-secondaryGrey-500">
            {userName?.charAt(0)}
          </span>
        )}
        <span
          className={`rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold ${
            !isPremium && !!seance.premium && "opacity-30"
          }`}
        >
          {getDurationInHours(Number(seance.duration))}
        </span>
      </div>
    </div>
  );
}

function getCardImage(duration: number) {
  if (duration <= 70) return CardBgGrey300;
  else if (duration <= 90) return CardBgPaleGreen200;
  else if (duration <= 120) return CardBgLightGreen500;
  else if (duration <= 150) return CardBgPaleGreen400;
  else if (duration <= 180) return CardBgPink200;
  else if (duration <= 210) return CardBgPink300;
  else if (duration <= 360) return CardBgPink400;
  else return CardBgPink500;
}
