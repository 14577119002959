export function CardBgPink500({
  width = 280,
  height = 164,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 280 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_5726)">
        <rect width="280" height="164" rx="20" fill="#C5205D" />
        <g clipPath="url(#clip1_77_5726)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.695 193.156C162.218 193.156 166.695 189.077 166.695 184.045C166.695 143.162 144.576 111.569 118.79 92.9048C113.45 89.0396 107.897 85.6867 102.283 82.9026C102.95 78.4981 103.098 73.7131 102.666 68.5512C101.041 49.1586 86.0705 35.9597 68.1923 26.6967C50.2497 17.4004 27.1575 10.8629 4.79599 5.41901C-3.49657 3.40018 -11.63 1.54249 -19.4727 -0.248791C-33.3631 -3.42138 -46.3413 -6.38563 -57.6759 -9.66785C-66.4304 -12.2029 -73.5045 -14.7445 -78.6744 -17.4025C-84.045 -20.1638 -86.0217 -22.3748 -86.6137 -23.6333C-88.7892 -28.2583 -94.668 -30.4008 -99.7443 -28.4187C-104.821 -26.4365 -107.172 -21.0803 -104.997 -16.4552C-101.825 -9.71345 -95.2454 -4.99903 -88.5034 -1.53271C-81.5606 2.03682 -72.9662 5.02491 -63.7338 7.69836C-51.8627 11.1359 -37.7679 14.3591 -23.4068 17.6432C-15.7028 19.405 -7.92208 21.1843 -0.366935 23.0236C21.89 28.4421 42.7879 34.4969 58.3058 42.5369C73.8881 50.6103 81.8486 59.4918 82.7238 69.9381C82.8941 71.9704 82.9454 73.8672 82.8943 75.6374C81.4654 75.277 80.044 74.9586 78.633 74.6833C65.688 72.157 51.9656 73.003 41.9232 80.3703C36.8311 84.106 32.8715 88.4433 31.0863 93.4398C29.1717 98.7983 30.0667 104.132 33.2214 108.497C38.8947 116.347 50.1863 119.275 59.7653 119.156C70.0314 119.028 81.6843 115.514 90.4132 106.966C92.407 105.013 94.1945 102.852 95.7517 100.482C99.2838 102.402 102.84 104.632 106.35 107.173C128.064 122.89 146.695 149.49 146.695 184.045C146.695 189.077 151.172 193.156 156.695 193.156ZM77.1232 93.0911C76.6818 93.6179 76.2291 94.1046 75.7703 94.5539C71.3362 98.8964 65.2059 100.864 59.4915 100.935C54.0832 101.003 51.184 99.4758 50.2466 98.7678C50.6079 98.0873 51.6584 96.6234 54.4664 94.5634C57.924 92.0268 64.5141 90.5634 74.4441 92.5013C75.3239 92.673 76.2175 92.8696 77.1232 93.0911Z"
            fill="#EC7598"
          />
          <path
            d="M-19.6887 7.01747C-14.2094 5.40652 -8.2219 8.46478 -6.3152 13.8483L-1.10683 28.554C0.799866 33.9375 -2.09628 39.6076 -7.57556 41.2186L-25.2712 46.4212C-30.7504 48.0322 -36.738 44.9739 -38.6446 39.5904L-43.853 24.8847C-45.7597 19.5012 -42.8636 13.8311 -37.3843 12.2201L-19.6887 7.01747Z"
            fill="#E14277"
          />
          <path
            d="M74.6444 151.242C79.6989 153.901 81.3182 159.967 78.2613 164.792L75.8563 168.587C72.7993 173.411 66.2237 175.166 61.1692 172.507L56.1334 169.859C51.0789 167.2 49.4596 161.134 52.5166 156.309L54.9215 152.514C57.9785 147.69 64.5541 145.935 69.6087 148.593L74.6444 151.242Z"
            fill="#E14277"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_5726">
          <rect width="280" height="164" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_5726">
          <rect
            width="300"
            height="227.778"
            fill="white"
            transform="translate(-120 -31.8887)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
