export function BannerGrey400({
  width = 1080,
  height = 210,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_779_8870)">
        <rect width="1080" height="210" fill="#657F91" />
        <g clipPath="url(#clip1_779_8870)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M715.208 -1.76287C717.838 -6.61906 723.907 -8.42339 728.763 -5.79295C754.2 7.98548 777.908 28.6127 795.192 58.9925C812.456 89.3378 822.999 128.864 822.999 180C822.999 185.523 818.522 190 812.999 190C807.477 190 802.999 185.523 802.999 180C802.999 131.636 793.044 95.6621 777.808 68.8824C762.592 42.1372 741.801 24.0145 719.238 11.7929C714.381 9.16244 712.577 3.09333 715.208 -1.76287Z"
            fill="#9CBBCF"
          />
          <path
            d="M929.332 99.4808C928.302 94.0548 923.069 90.4909 917.643 91.5207L897.993 95.2499C892.567 96.2797 889.004 101.513 890.033 106.939C891.063 112.365 896.297 115.929 901.723 114.899L921.372 111.17C926.798 110.14 930.362 104.907 929.332 99.4808Z"
            fill="#7A97AA"
          />
          <path
            d="M878.572 152.426C879.981 157.766 885.452 160.954 890.792 159.545C896.132 158.137 899.32 152.666 897.911 147.326C896.503 141.985 891.032 138.798 885.692 140.206C880.351 141.615 877.164 147.086 878.572 152.426Z"
            fill="#7A97AA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M857.5 103C863.023 103 867.5 107.477 867.5 113C867.5 116.736 866.117 123.551 864.206 130.685C862.174 138.27 859.184 147.53 855.316 156.643C851.483 165.673 846.597 175.018 840.625 182.452C834.818 189.682 826.766 196.599 816.37 197.674C807.183 198.624 797.388 195.466 788.861 191.517C780.059 187.44 771.214 181.852 763.473 176.373C755.689 170.865 748.78 165.297 743.83 161.119C741.349 159.026 739.344 157.268 737.951 156.026C737.254 155.404 736.709 154.911 736.332 154.567C736.144 154.396 735.998 154.261 735.896 154.168L735.776 154.057L735.727 154.011C735.726 154.01 735.725 154.009 742.538 146.689L735.725 154.009C731.682 150.246 731.455 143.919 735.218 139.876C738.98 135.834 745.306 135.606 749.349 139.368L749.351 139.369L749.367 139.384L749.45 139.461C749.528 139.532 749.648 139.643 749.81 139.791C750.135 140.087 750.624 140.53 751.261 141.098C752.537 142.236 754.403 143.872 756.729 145.835C761.392 149.77 767.839 154.962 775.025 160.047C782.254 165.163 789.995 170.001 797.266 173.369C804.813 176.864 810.578 178.166 814.312 177.78C816.835 177.519 820.514 175.552 825.033 169.926C829.389 164.504 833.428 157.022 836.905 148.829C840.347 140.72 843.051 132.362 844.887 125.51C845.803 122.089 846.484 119.115 846.927 116.802C847.149 115.645 847.302 114.704 847.396 113.989C847.476 113.381 847.494 113.076 847.499 113.006C847.5 112.986 847.5 112.984 847.5 113C847.5 107.477 851.977 103 857.5 103Z"
            fill="#9CBBCF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_8870">
          <rect width="1080" height="210" fill="white" />
        </clipPath>
        <clipPath id="clip1_779_8870">
          <rect
            width="300"
            height="250"
            fill="white"
            transform="translate(630 -20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
