import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import enNs1 from "./locales/en/en-ns1.json";
import frNs1 from "./locales/fr/fr-ns1.json";

type AppLanguage = "fr" | "en";

const language: AppLanguage = "fr";

i18next.use(initReactI18next).init({
  lng: language,
  fallbackLng: "fr",
  ns: ["ns1"],
  // debug: true,
  resources: {
    fr: {
      ns1: frNs1,
    },
    en: {
      ns1: enNs1,
    },
  },
});

const translate = i18next.t;

export { language, translate };
