import { HiSearch } from "react-icons/hi";

interface IProps {
  placeholder?: string;
  disableIcon?: boolean;
  value: string;
  setValue: (value: string) => void;
  width?: string;
  height?: string;
  className?: string;
}

export function INSearchField({
  placeholder,
  disableIcon = false,
  value,
  setValue,
  width,
  height,
  className,
}: IProps) {
  return (
    <div
      className={`relative ${className || ""}`}
      style={{ width: width || "450px" }}
    >
      <input
        className={`w-full rounded-[30px] border border-darkGrey-200 px-5 text-sm font-normal text-darkGrey-700 focus-visible:outline-none ${
          disableIcon ? "" : "pl-12"
        }`}
        style={{ height: height || "52px" }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {!disableIcon && (
        <HiSearch
          className="absolute left-5 top-1/2 -translate-y-1/2 transform text-darkGrey-700"
          size={16}
          color="#485585"
        />
      )}
    </div>
  );
}
